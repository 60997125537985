import * as React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Zoom from '@material-ui/core/Zoom';

import LoadingButton from '@material-ui/lab/LoadingButton';
import { eventBus } from "../EventBus";

import { getUserGameSessionId, getGameSession, actOnGameSession, placeGameBet, Keyframes } from '../Utils';

const StackTile = ({ onClicked, tileType, disabled }) => {
    const [hovered, setHovered] = React.useState(false);
    const [clicked, setClicked] = React.useState(false);

    function handleClick() {
        if (!disabled && tileType == null) {
            setClicked(!clicked);
            onClicked();
        }
    }

    return (
        <Box onClick={() => handleClick()} onMouseOver={() => { if (tileType == null && !disabled) { setHovered(true); } }} onMouseOut={() => setHovered(false)} style={{ width: '100%', height: '100%', backgroundColor: (!clicked ? '#455057' : '#455057'), borderRadius: 5, transform: `${hovered ? 'scale(1.05)' : 'scale(1)'}`, transition: `transform .3s` }}>

            <Button style={{ height: '100%', width: '100%' }}>
                <Zoom in={tileType != 0}>
                    <h4>{tileType}</h4>
                </Zoom>
            </Button>
        </Box>
    );
}

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 48 * 4.5 + 8
        },
    },
};

export default function StackGame({ balance, tokenPrice, isSignedIn, webSocketConnected }) {
    const [gameSession, setGameSession] = React.useState(null);
    const [lastGameActionState, setLastGameActionState] = React.useState(null);

    const appMinesGridRef = React.useRef(null);

    const [width, setWidth] = React.useState(0);
    const [height, setHeight] = React.useState(0);

    const [clickedTiles, setClickedTiles] = React.useState([]);

    const [minesAmount, setMinesAmount] = React.useState(3);
    const [betAmount, setBetAmount] = React.useState(0);

    const [tiles, setTiles] = React.useState([]);

    const getTileMultiplier = (tile) => {
        for(var i = 0; i < tiles.length; i++) {
            if (tiles[i].tileNumber == tile) {
                return tiles[i].multiplier;
            }
        }

        return 0;
    }

    const updateDimensions = () => {
        setWidth(Math.min(630, appMinesGridRef.current.offsetWidth));
        setHeight(Math.min(630, appMinesGridRef.current.offsetWidth));
    }

    React.useEffect(() => {
        setWidth(Math.min(630, appMinesGridRef.current.offsetWidth));
        setHeight(Math.min(630, appMinesGridRef.current.offsetWidth));
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    const [currentTimeout, setCurrentTimeout] = React.useState(0);

    const [isBusy, setIsBusy] = React.useState(false);
    React.useEffect(() => {
        if (!webSocketConnected)
          return;
    
        eventBus.dispatch("subscribe", { channel: "game", subChannel: "stack" });
        eventBus.dispatch("subscribe", { channel: "gameHistory", subChannel: "stack" });
    
        function eventBusCallback(data) {
            if(data.data.type == "GAME_EVENT")
            {
                console.log(data.data.data);
            setTiles(prevState => ([...prevState, data.data.data]));
            }
        }
    
        let thing = eventBus.on("game", eventBusCallback);
    
        return () => {
          eventBus.remove("game", thing);
          eventBus.dispatch("unSubscribe", { channel: "game", subChannel: "stack" });
          eventBus.dispatch("unSubscribe", { channel: "gameHistory", subChannel: "stack" });
        };
      }, [webSocketConnected]);
    return (
        <Box style={{ width: '100%', borderRadius: '5px', backgroundColor: '#213743' }}>
            <Keyframes name="scaleAnimLoadMine" from={{ transform: `scale(1)` }} to={{ transform: `scale(1.075)` }} />

            <Grid container direction="row-reverse" style={{ width: '100%', height: '100%' }}>

                <Grid item xs={12} md={9} ref={appMinesGridRef} align="center" style={{ borderRadius: '5px', backgroundColor: '#0c1a24' }}>
                    <Grid container columns={{ xs: 7 }} spacing={1} style={{ padding: 10, height, width }}>
                        {[...Array(56)].map((x, i) => <Grid item xs={1} key={i}>{
                            gameSession == null ?
                                <StackTile key={i} tileType={getTileMultiplier(i)} clickedInitial={null} disabled onClicked={() => {
                                    setClickedTiles(prevState => [...prevState, ]);
                                }} />
                                :
                                <StackTile key={i} tileType={getTileMultiplier(i)} clickedInitial={lastGameActionState.publicState.minesPressed.includes(i + 1)}  />
                        }</Grid>
                        )}
                    </Grid>
                </Grid>

                <Grid item xs={12} md={3} style={{ paddingTop: 15 }}>
                    <TextField
                        id="outlined-number"
                        type="number"
                        margin="dense"
                        //   defaultValue={0}
                        value={betAmount}
                        onChange={(event) => {
                            setBetAmount(event.target.value)
                        }}
                        sx={{ m: 0.75, width: '75%' }}
                        InputProps={{
                            startAdornment: localStorage.balanceDisplayCurrency == "USD" && <InputAdornment position="start">$</InputAdornment>,
                        }}
                        label="2X"
                    />
                    <TextField
                        id="outlined-number"
                        type="number"
                        //   defaultValue={0}
                        margin="dense"
                        value={betAmount}
                        onChange={(event) => {
                            setBetAmount(event.target.value)
                        }}
                        sx={{ m: 0.75, width: '75%' }}
                        InputProps={{
                            startAdornment: localStorage.balanceDisplayCurrency == "USD" && <InputAdornment position="start">$</InputAdornment>,
                        }}
                        label="4X"
                    />
                    <TextField
                        id="outlined-number"
                        type="number"
                        //   defaultValue={0}
                        margin="dense"
                        value={betAmount}
                        onChange={(event) => {
                            setBetAmount(event.target.value)
                        }}
                        sx={{ m: 0.75, width: '75%' }}
                        InputProps={{
                            startAdornment: localStorage.balanceDisplayCurrency == "USD" && <InputAdornment position="start">$</InputAdornment>,
                        }}
                        label="8X"
                    />
                    <TextField
                        id="outlined-number"
                        type="number"
                        //   defaultValue={0}
                        margin="dense"
                        value={betAmount}
                        onChange={(event) => {
                            setBetAmount(event.target.value)
                        }}
                        sx={{ m: 0.75, width: '75%' }}
                        InputProps={{
                            startAdornment: localStorage.balanceDisplayCurrency == "USD" && <InputAdornment position="start">$</InputAdornment>,
                        }}
                        label="14X"
                    />
                    <TextField
                        id="outlined-number"
                        type="number"
                        margin="dense"
                        //   defaultValue={0}
                        value={betAmount}
                        onChange={(event) => {
                            setBetAmount(event.target.value)
                        }}
                        sx={{ m: 0.75, width: '75%' }}
                        InputProps={{
                            startAdornment: localStorage.balanceDisplayCurrency == "USD" && <InputAdornment position="start">$</InputAdornment>,
                        }}
                        label="28X"
                    />
                    <TextField
                        margin="dense"
                        id="outlined-number"
                        type="number"
                        //   defaultValue={0}
                        value={betAmount}
                        onChange={(event) => {
                            setBetAmount(event.target.value)
                        }}
                        sx={{ m: 0.75, width: '75%' }}
                        InputProps={{
                            startAdornment: localStorage.balanceDisplayCurrency == "USD" && <InputAdornment position="start">$</InputAdornment>,
                        }}
                        label="56X"
                    />
                    <TextField
                        disabled
                        margin="dense"
                        id="outlined-number"
                        type="number"
                        //   defaultValue={0}
                        value={betAmount}
                        onChange={(event) => {
                            setBetAmount(event.target.value)
                        }}
                        sx={{ m: 3, width: '75%' }}
                        InputProps={{
                            startAdornment: localStorage.balanceDisplayCurrency == "USD" && <InputAdornment position="start">$</InputAdornment>,
                        }}
                        label="Total Bet Amount (0 picks)"
                    />

                    <LoadingButton disabled loading={isBusy} variant="contained" color="success" sx={{ m: 3, width: '75%', minHeight: '8%' }} onClick={() => {
                        setIsBusy(true);

                        placeGameBet("mines", [{ amount: betAmount, data: { amountBombs: minesAmount } }]).then((data) => {
                            getGameSession(data.sessionId).then((data) => {
                                setIsBusy(false);
                                setLastGameActionState(data.state);
                                setGameSession(data);
                                //      setBetAmount(translateAmount(betAmount, tokenPrice));
                            });
                        }).catch(() => {
                            setIsBusy(false);
                        });
                    }}>Make at least one pick</LoadingButton>
                </Grid>
            </Grid>
        </Box>
    );
}