import * as React from 'react';
import { showToast, fetchAPI } from '../Utils.js';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import LoadingButton from '@material-ui/lab/LoadingButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import TabPanel from '../TabPanel.js';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

const DetailsTab = () => {
    const [isPerformingAction, setIsPerformingAction] = React.useState(false);

    const [formData, setFormData] = React.useState({ emailAddress: "", oldPassword: "", newPassword: "", confirmNewPassword: "", twoFactorAuthCode: 0 });

    React.useEffect(() => {
        setIsPerformingAction(true);

        fetchAPI("account/details", "GET").then((data) => {
            setFormData(prevState => ({ ...prevState, ...data }));
            setIsPerformingAction(false);
        });
    }, []);

    const updateDetails = () => {
        if (formData.newPassword == "" && formData.confirmNewPassword == "")
            setFormData(prevState => ({ ...prevState, newPassword: prevState.oldPassword, confirmNewPassword: prevState.oldPassword }));

        if (formData.newPassword != formData.confirmNewPassword) {
            alert("new: " + formData.newPassword + " con: " + formData.confirmNewPassword);
            setIsPerformingAction(false);
            showToast("error", "Passwords don't match!");
            return;
        }

        fetchAPI("account/details", "POST", formData).then((data) => {
            localStorage.authToken = data.token;
            showToast("success", data.message);
        }).finally(() => {
            setIsPerformingAction(false);
        })
    }

    return (
        <FormControl style={{width: '100%'}}>
            <TextField margin="dense" id="outlined-basic" label="Email Address (optional)" variant="outlined" value={formData.emailAddress} onChange={(e) => setFormData(prevState => ({...prevState, emailAddress: e.target.value })) } />
            <TextField margin="dense" type="password" id="outlined-basic" label="Current Password" variant="outlined" onChange={(e) => setFormData(prevState => ({...prevState, oldPassword: e.target.value }))} />
            <TextField margin="dense" type="password" id="outlined-basic" label="New Password" variant="outlined" onChange={(e) => setFormData(prevState => ({...prevState, newPassword: e.target.value }))} />
            <TextField margin="dense" type="password" id="outlined-basic" label="Confirm New Password" variant="outlined" onChange={(e) => setFormData(prevState => ({...prevState, confirmNewPassword: e.target.value }))} />
            <TextField type="number" margin="dense" id="outlined-basic" label="2-FA Code (if enabled)" variant="outlined" onChange={(e) => setFormData(prevState => ({...prevState,  twoFactorAuthCode: parseInt(e.target.value) }))} />
            <LoadingButton loading={isPerformingAction} style={{ marginTop: 10 }} variant="contained" onClick={() => {
                setIsPerformingAction(true);
                updateDetails();
            }}>Update Details</LoadingButton>
        </FormControl>);
}

const TwoFactorTab = () => {
    const [isPerformingAction, setIsPerformingAction] = React.useState(false);

    const [enabled, setEnabled] = React.useState(null);
    const [activationData, setActivationData] = React.useState(null);

    const [formData, setFormData] = React.useState({ password: "", twoFactorAuthCode: 0 });

    React.useEffect(() => {
        setIsPerformingAction(true);

        fetchAPI("account/twofactor", "GET").then((data) => {
            setEnabled(data.enabled);
            setIsPerformingAction(false);
        });
    }, []);

    const enable = () => {
        fetchAPI("account/twofactor", "POST", formData).then((data) => {
            setActivationData(data);
            setFormData({ password: "", twoFactorAuthCode: 0 });
        }).finally(() => {
            setIsPerformingAction(false);
        });
    }

    const confirmEnable = () => {
        fetchAPI("account/twofactor", "PUT", { ...activationData, ...formData }).then((data) => {
            setEnabled(true);
            setActivationData(null);
            setFormData({ password: "", twoFactorAuthCode: 0 });
            showToast("success", "Two factor authentication successfully enabled!");
        }).finally(() => {
            setIsPerformingAction(false);
        });
    }

    const disable = () => {
        fetchAPI("account/twofactor", "DELETE", formData).then((data) => {
            setEnabled(false);
            setActivationData(null);
            setFormData({ password: "", twoFactorAuthCode: 0 });
            showToast("success", "Two factor authentication successfully disabled!");
        }).finally(() => {
            setIsPerformingAction(false);
        });
    }

    return (
        <FormControl>
            {enabled ? <>
                <TextField margin="dense" id="outlined-basic" label="Two Factor Code" variant="outlined" onChange={(e) => { setFormData(prevState => ({ ...prevState, twoFactorAuthCode: parseInt(e.target.value) })) }} />
                <LoadingButton loading={isPerformingAction} style={{ marginTop: 10 }} variant="contained" onClick={() => {
                    setIsPerformingAction(true);
                    disable();
                }}>Disable Two Factor Authentication</LoadingButton></> : (activationData == null ?
                    <>
                        <p>You currently do not have Two Factor Authentication enabled</p>
                        <TextField margin="dense" type="password" value="" id="password-entry" label="Password" variant="outlined" onChange={(e) => { setFormData(prevState => ({ ...prevState, password: e.target.value })) }} /> {/*confirm pass*/}
                        <LoadingButton loading={isPerformingAction} style={{ marginTop: 10 }} variant="contained" onClick={() => {
                            setIsPerformingAction(true);
                            enable();
                        }}>Enable Two Factor Authentication</LoadingButton></>
                    :
                    <>
                        <p>Scan the following QR code in your desired authenticator app (for instance Authy or Google Auth), then type in the code from your authenticator app and click enable</p>
                        <img style={{ marginLeft: 'auto', marginRight: 'auto', height: '25%', width: '25%' }} src={activationData.qrCodeUrl} />
                        <TextField type="number" margin="normal" value="" id="twofa-code" label="Two Factor Code" variant="outlined" onChange={(e) => { setFormData(prevState => ({ ...prevState, twoFactorAuthCode: parseInt(e.target.value) })) }} /> {/*confirm pass*/}
                        <LoadingButton loading={isPerformingAction} style={{ marginTop: 10 }} variant="contained" onClick={() => {
                            setIsPerformingAction(true);
                            confirmEnable();
                        }}>Enable Two Factor Authentication</LoadingButton></>
            )}
        </FormControl>);
}

export default function AccountDialog() {
    const [tab, setTab] = React.useState(0);

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tab} variant="scrollable" allowScrollButtonsMobile onChange={(e, index) => setTab(index)}>
                    <Tab label="Details" />
                    <Tab label="Two Factor Auth" />
                 {//   <Tab label="Wallet Link" />
}
                </Tabs>
            </Box>
            <TabPanel value={tab} index={0}>
                <DetailsTab />
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <TwoFactorTab />
            </TabPanel>
            <TabPanel value={tab} index={2}>
                <h3>soon TM</h3>
            </TabPanel>
        </>
    );
}