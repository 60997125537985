import * as React from 'react';
import MinesGame from '../game/Mines.js';
import { eventBus } from '../EventBus.js';
import {
    useParams
} from "react-router-dom";

import CrashGame from './Crash.js';
import StackGame from './Stack.js'


export default function GameView({ webSocketConnected, balance, tokenPrice, isSignedIn }) {
    let { game } = useParams();

    const getGame = () => {
        switch(game) {
            case "mines": {
                return (<MinesGame webSocketConnected={webSocketConnected} balance={balance} tokenPrice={tokenPrice} isSignedIn={isSignedIn} />);
            }
            case "crash": {
                return (<CrashGame webSocketConnected={webSocketConnected} balance={balance} tokenPrice={tokenPrice} isSignedIn={isSignedIn} />);
            }
            case "stack": {
                return (<StackGame webSocketConnected={webSocketConnected} balance={balance} tokenPrice={tokenPrice} isSignedIn={isSignedIn} />);
            }
        }
    }

 /*   React.useEffect(() => {
        eventBus.on("wsMessage", (data) =>
            console.log(data)
        );
    }, []);*/

    return (<div>{getGame()}</div>);
}