import * as React from 'react';

import { fetchAPI, timestampToDate, formatCurrency, formatMultiplier } from './Utils';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Pagination from '@material-ui/core/Pagination';

import Skeleton from '@material-ui/core/Skeleton';

export default function PaginatedList({ keys, path, body, useGet }) {
    const [items, setItems] = React.useState([]);
    const [pageInfo, setPageInfo] = React.useState({ currentPage: 0, totalPages: 0 });
    const [loading, setLoading] = React.useState(false);
  
    function fetchItems(page) {
      setLoading(true);

      fetchAPI(path, useGet ? "GET" : "POST", useGet ? null : { ...body, skip: page * 20 }).then((data) => {
        setLoading(false);
        setItems(data.data);
        setPageInfo({ currentPage: page, totalPages: Math.ceil(data.totalDocuments / 20) });
      });
    }
  
    React.useEffect(() => {
      fetchItems(0);
    }, [path]);
  
    const parse = (type, value/*, keysObj, item*/) => {
      switch (type) {
        case "STR_ARRAY": {
          return JSON.stringify(value);
        }
        case "STRING": {
          return value;
        }
        case "CURRENCY": {
          return formatCurrency(value);
        }
        case "DATE": {
          return timestampToDate(value * 1000);
        }
        case "ARR_LENGTH": {
          return value.length;
        }
        case "BOOLEAN": {
          return value ? "true" : "false";
        }
        case "MULTIPLIER": {
          return formatMultiplier(value);
        }
        /*case "CUSTOM": {
          return keysObj.parse(item);
        }*/
        case "PRETTY_STRING": {
          return value.replaceAll("_", " ").toLowerCase().replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase());
        }
        default: {
          return value;
        }
      }
    }
  
    return (
      <div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {Object.keys(keys).map((key, i) => (
                  <><TableCell key={i} align={i > 0 ? "right" : ""}>{keys[key].name}</TableCell></>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? [...Array(20)].map((e, i) => ( <TableRow
                  key={i}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  {Object.keys(keys).map((key, _i) => (
                    <><TableCell key={_i} align={_i > 0 ? "right" : ""} component={_i > 0 ? "th" : ""} scope={_i > 0 ? "row" : ""}><Skeleton /></TableCell></>
                  ))}
                </TableRow>)) :
              items.map((item, i) => (
                <TableRow
                  key={i}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  {Object.keys(keys).map((key, _i) => (
                    <><TableCell key={_i} align={_i > 0 ? "right" : ""} component={_i > 0 ? "th" : ""} scope={_i > 0 ? "row" : ""}>{parse(keys[key].type, item[key]/*, keys[key], item*/)}</TableCell></>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Pagination count={pageInfo.totalPages} page={pageInfo.currentPage + 1} onChange={(e, v) => fetchItems(v - 1)} />
        </div>
      </div>
    );
  }