import { toast } from 'react-toastify';
import { useLocation } from "react-router-dom";
import Error from '@material-ui/icons/Error';
import Warning from '@material-ui/icons/Warning';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Info from '@material-ui/icons/Info';

import * as React from 'react';

export const CURRENCY_DENOMINATOR = "$";

export function usePrevious(value) {
    const ref = React.useRef();
    React.useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const Keyframes = (props) => {
    const toCss = (cssObject) =>
        typeof cssObject === "string"
            ? cssObject
            : Object.keys(cssObject).reduce((accumulator, key) => {
                const cssKey = key.replace(/[A-Z]/g, v => `-${v.toLowerCase()}`);
                const cssValue = (cssObject)[key].toString().replace("'", "");
                return `${accumulator}${cssKey}:${cssValue};`;
            }, "");

    return (
        <style>
            {`@keyframes ${props.name} {
          ${Object.keys(props)
                    .map(key => {
                        return ["from", "to"].includes(key)
                            ? `${key} { ${toCss(props[key])} }`
                            : /^_[0-9]+$/.test(key)
                                ? `${key.replace("_", "")}% { ${toCss(props[key])} }`
                                : "";
                    })
                    .join(" ")}
        }`}
        </style>
    );
};

export function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

export function showToast(type, message) {
    switch (type) {
        case "warning": {
            toast.error(<div style={{ fontSize: 14, color: 'white' }}><Warning style={{ marginBottom: -7, color: 'orange' }} /> {message}</div>, { style: { backgroundColor: '#132a36' } });
            //toast.error(<div style={{ fontSize: 14, color: 'white' }}><Error style={{ marginBottom: -7, color: 'white' }} /> {message}</div>); //space:⠀
            break;
        }
        case "error": {
            toast.error(<div style={{ fontSize: 14, color: 'white' }}><Error style={{ marginBottom: -7, color: '#fe2247' }} /> {message}</div>, { style: { backgroundColor: '#132a36' } });
            //toast.error(<div style={{ fontSize: 14, color: 'white' }}><Error style={{ marginBottom: -7, color: 'white' }} /> {message}</div>); //space:⠀
            break;
        }
        case "success": {
            toast.success(<div style={{ fontSize: 14, color: 'white' }}><CheckCircle style={{ marginBottom: -7, color: '#1ed621' }} /> {message}</div>, { style: { backgroundColor: '#132a36' } });
            break; //show checmark
        }
        case "info": {
            toast.info(<div style={{ fontSize: 14, color: 'white' }}><Info style={{ marginBottom: -7, color: '#3db2fc' }} /> {message}</div>, { style: { backgroundColor: '#132a36' } });
            break; //show checmark
        }
        default: {
            toast(<div style={{ fontSize: 14, color: 'white' }}>{message}</div>, { style: { backgroundColor: '#132a36' } });
            break;
        }
    }
}

export function formatCurrency(amount) {
    return "$" + amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //  return amount.toFixed(2) + " β";
}

export function formatWebSocketAlertMessage(message) {
    const currencyRegex = /\{(.*?)\}/g;
    const currencyAmount = message.match(currencyRegex)[0].replace("{", "").replace("}", "");

    return message.replace("{" + currencyAmount + "}", formatCurrency(parseFloat(currencyAmount)));
}

export function formatMultiplier(multiplier) {
    return multiplier.toFixed(2) + "x";
}

export function timestampToDate(timestamp) {
    return new Date(timestamp).toLocaleString("en-US");
}

export function timestampToTime(timestamp) {
    return new Date(timestamp).toLocaleTimeString("en-US");
}

export function fetchAPI(path, method = 'POST', body, errorNotification = true, token = localStorage.authToken) {
    return fetch("https://api-casino-demo.kivem.net/" + path, { method, body: (method != "GET" ? JSON.stringify(body) : null), headers: { 'Content-Type': (body && method != "GET" ? 'application/json' : 'text/plain'), 'Authorization': 'Bearer ' + token } }).then(response => response.json().catch(error => {
        //showToast("error", "Error sending request to " + path);
        return Promise.reject("Error sending request to " + path);
    })).then(data => {
        if (data.error != null && errorNotification) {
            showToast("error", data.error.message);
            return Promise.reject(data.error.message);
        }

        return Promise.resolve(data);
        //return data;
    }).catch((err) => {
        console.log(err);
        //    showToast("error", err + "");
        //showToast("error", "Error sending request to " + path);
        if (errorNotification && err.message != undefined) {
            showToast("error", path + ": " + err.message);
        }

        return Promise.reject(err);
        //return Promise.reject("Error sending request to " + path);
    });
}

export function getUserGameSessionId(game) {
    return fetchAPI("games/" + game + "/self", "GET").then((data) => {
        return data.sessionId;
    }).catch(() => {
        return Promise.reject("Error getting self session id");
    });
}

export function getGameSession(sessionId) {
    return fetchAPI("sessions/" + sessionId + "/info", "GET").then((data) => {
        return data;
    }).catch(() => {
        return Promise.reject("Error getting session data");
    });
}

export function actOnGameSession(sessionId, action) {
    return fetchAPI("sessions/" + sessionId + "/act", "POST", { action: action }).then((data) => {
        return data;
    }).catch(() => {
        return Promise.reject("Error performing session action");
    });
}

export function placeGameBet(game, bets) {
    return fetchAPI("games/" + game + "/bet", "POST", { bets: bets }).then((data) => {
        return data;
    }).catch(() => {
        return Promise.reject("Error placing bet");
    });
}

export function makeString(length) {
    var result = '';

    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }

    return result;
}

export function useInterval(callback, delay) {
    const savedCallback = React.useRef(callback)

    React.useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    React.useEffect(() => {
        if (delay === null) {
            return
        }

        const id = setInterval(() => savedCallback.current(), delay)

        return () => clearInterval(id)
    }, [delay])
}