import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";

export default function IgidaDialog({ children, open, handleClose, title, isClosable }) {
    return (
        <Dialog
            open={open}
            color="info"
            maxWidth="lg"
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" style={{ paddingBottom: 0, justifyContent: 'center' }}>
                {title}
                {isClosable &&
                    <IconButton
                        style={{ float: 'right', marginTop: '-3px' }}
                        edge="end"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <Close />
                    </IconButton>}
            </DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
        </Dialog>
    );
}