import * as React from 'react';
import { useQuery, showToast, fetchAPI, translateAmountToCurrencyWithPrefix, CURRENCY_DENOMINATOR, usePrevious, parseJwt, formatCurrency, formatWebSocketAlertMessage } from './Utils.js';
import { eventBus } from './EventBus.js';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Chat from '@material-ui/icons/Chat';
import Divider from '@material-ui/core/Divider';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Tooltip from '@material-ui/core/Tooltip';
import Skeleton from '@material-ui/core/Skeleton';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import WarningIcon from '@material-ui/icons/Warning';

import ACPLoginDialog from './modals/ACPLogin.js';

import IgidaDialog from './modals/IgidaDialog.js';
import LoginDialog from './modals/LoginRegister.js';
import AccountDialog from './modals/Account.js';
import StatsDialog from './modals/Stats.js';
import ToSDialog from './modals/ToS.js';
import BetsDialog from './modals/Bets.js';
import TransactionsDialog from './modals/Transactions.js';
import SeedPairDialog from './modals/SeedPairs.js';
import GameView from './game/GameView.js';
import CategoryView from './game/CategoryView.js';
import BetsTable from './BetsTable.js';
import WalletDialog from './modals/Wallet.js';

import ProvablyFair from './ProvablyFair.js';

import AdminPanel from './Admin.js';

import ChatDrawer from './Chat.js';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    useHistory,
    Link
} from "react-router-dom";

const Navbar = ({ balance, isSignedIn }) => {

}

export default function MainApp() {
    const history = useHistory();

    const [accountMenuAnchor, setAccountMenuAnchor] = React.useState(null);

    const [chatOpen, setChatOpen] = React.useState(false);

    const [hasCheckedToken, setHasCheckedToken] = React.useState(false);

    const [showingInUSD, setShowingInUSD] = React.useState((localStorage.balanceDisplayCurrency == "TOKEN" ? false : true));
    const [isSignedIn, setIsSignedIn] = React.useState(false);

    const [webSocket, setWebSocket] = React.useState(null);

    const [balance, setBalance] = React.useState(null);
    const [currentPrice, setCurrentPrice] = React.useState(1);

    const [webSocketConnected, setWebSocketConnected] = React.useState(false);

    const [balanceChanged, setBalanceChanged] = React.useState(0);

    const [adminStatus, setAdminStatus] = React.useState({ hasAdminCapabilities: false, token: null });

    const previousBalance = usePrevious(balance);

    React.useEffect(() => {
        setBalanceChanged(balance > previousBalance ? 1 : (balance < previousBalance ? -1 : 0));

        var timeout = setTimeout(() => {
            setBalanceChanged(0);
        }, 300);

        return () => clearTimeout(timeout);
    }, [balance]);

    //let query = useQuery();

    const convertToUSD = (tokens) => {
        return currentPrice * tokens;
    }

    const convertFromUSD = (usd) => {
        return usd / currentPrice;
    }
    //25.39.177.251
    const connectWebSocket = (isReconnection = false) => {
        let socket = new WebSocket("wss://api-casino-demo.kivem.net/ws/subscriptions?authToken=" + localStorage.authToken);

        setWebSocket(socket);

        socket.onopen = function (event) {
            setWebSocketConnected(true);
            console.log("connected");

            if (isReconnection)
                showToast("success", "Reconnected!");
        };

        socket.onclose = function (event) {
            setWebSocketConnected(false);

            if(event.code == 3500) {
                return;
            }

            if (isSignedIn) {
                showToast("error", "Lost connection, trying to reconnect...");

                setTimeout(function () {
                    connectWebSocket(true);
                }, 5000);
            }
        };

        /*socket.onerror = function (event) {
          showToast("error", "Websocket error encountered");
        };*/

        socket.onmessage = function (event) {
            let jsonData = JSON.parse(event.data);

            switch (jsonData.type) {
                case "CHANNEL_SUBSCRIPTION_MESSAGE": {
                    switch (jsonData.data.channel) {
                        case "gameHistory": {
                            eventBus.dispatch("gameHistory", jsonData.data.data);
                            break;
                        }
                        case "pricing": {
                            setCurrentPrice(jsonData.data.data.usdPricePerUnit);
                            break;
                        }
                        case "player": {
                            switch (jsonData.data.data.type) {
                                case "BALANCE_UPDATE": {
                                    setBalance(jsonData.data.data.data.newBalance);
                                    break;
                                }
                                case "MESSAGE": {
                                    showToast(jsonData.data.data.data.type, formatWebSocketAlertMessage(jsonData.data.data.data.message));
                                    break;
                                }
                            }
                            //if (jsonData.data.data.type == "BALANCE_UPDATE") {
                            //  setBalance(jsonData.data.data.data.newBalance);
                            break;
                        }
                        case "bets": {
                            if (jsonData.data.subChannel == "all") {
                                eventBus.dispatch("allBets", jsonData.data.data);
                            } else {
                                eventBus.dispatch("personalBets", jsonData.data.data);
                            }

                            break;
                        }
                        case "game": {
                            eventBus.dispatch("game", jsonData.data.data);
                            break;
                        }
                        default: {
                            break;
                        }
                    }

                    break;
                }
                case "CHANNEL_SUBSCRIPTION_STATUS": {
                    if (jsonData.data.type == "SUBSCRIPTION_FAILED")
                        showToast("error", "Failed to subscribe to channel" + jsonData.data.channel + " (subchannel " + jsonData.data.subChannel + ")");
                    if (jsonData.data.type == "KICKED")
                        showToast("error", "Kicked from channel " + jsonData.data.channel + " (subchannel " + jsonData.data.subChannel + ")");
                    if (jsonData.data.type == "PUBLISH_FAILED")
                        showToast("error", "Failed to publish message to " + jsonData.data.channel + " (subchannel " + jsonData.data.subChannel + ")");
                    break;
                }
                default: {
                    showToast("error", "Message type not handled: " + jsonData.data.type);
                    break;
                }
            }
        };

        eventBus.on("subscribe", (data) => {
            console.log(data);
            socket.send(JSON.stringify({ type: "CHANNEL_SUBSCRIBE", data: { channel: data.channel, subChannel: data.subChannel } }));
        });

        eventBus.on("unSubscribe", (data) => {
            socket.send(JSON.stringify({ type: "CHANNEL_UNSUBSCRIBE", data: { channel: data.channel, subChannel: data.subChannel } }));
        });
    };

    React.useEffect(() => {
        if (isSignedIn) {
            //    if(webSocket != null)
            //      webSocket.close();
            fetchAPI("account/info", "GET").then((data) => {
                setBalance(data.balance);
            });

            if (webSocket == null)
                connectWebSocket();

            setAdminStatus({ hasAdminCapabilities: parseJwt(localStorage.authToken).roles.includes("ADMIN"), token: null });
        }
    }, [isSignedIn]);

    React.useEffect(() => {
        if (localStorage.authToken != null) {
            fetchAPI("account/info", "GET", null, false).then(() => {
                setIsSignedIn(true);
                setHasCheckedToken(true);
            }).catch((data) => {
                if(data && data.error && data.error.message) {
                    setIsSignedIn(false);
                    localStorage.removeItem("authToken");
                    history.push("/");
                    webSocket.close(3500);
                    setWebSocket(null);
                    setAccountMenuAnchor(null);
                    setBalance(null);
                }

                setHasCheckedToken(true);
            });
        } else {
            setHasCheckedToken(true);
        }
    }, []);

    return (
        <div className="App">
           {/* <ChatDrawer />*/}
            <Box sx={{ boxShadow: 5 }}>

                <AppBar elevation={1} style={{ ...{ marginBottom: 30 } }} position="static" color="default"> {/*0.75 elevation*/}
                    <Container maxWidth="lg">
                        <Toolbar>
                            {// <img onClick={() => history.push("/")} style={{ cursor: 'pointer', marginRight: 20 }} height={64} width={200} src="https://i.imgur.com/sU0juqg.png" />
                            }
                            <Typography variant="h4" component="h4" onClick={() => history.push("/")} style={{ fontFamily: 'Fredoka One', userSelect: 'none', cursor: 'pointer' }}>
                                Kivem
                            </Typography>


                            <ButtonGroup size="large" sx={{ marginLeft: 'auto', marginRight: 'auto' }} aria-label="large button group">
                                <Button size="large" color="secondary" variant="contained" style={{ textTransform: 'lowercase', color: (balanceChanged == 0 ? 'white' : (balanceChanged == -1 ? '#d42a1e' : '#1ed44e')), transition: `all .25s ease-out` }}>{balance == null ? <Skeleton animation="wave" variant="text" width={90} height={30} /> : formatCurrency(balance)}</Button>
                                <Button size="m" color="primary" variant="contained" onClick={() => history.push("?modal=wallet")}>Wallet</Button>
                            </ButtonGroup>

                            <div sx={{ marginLeft: 'auto' }}>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="primary-search-account-menu"
                                    aria-haspopup="true"
                                    color="inherit"
                                    onClick={(e) => setAccountMenuAnchor(e.currentTarget)}
                                >
                                    <AccountCircle />
                                </IconButton>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={accountMenuAnchor}
                                    open={accountMenuAnchor != null}
                                    onClose={() => setAccountMenuAnchor(null)}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem onClick={() => history.push("?modal=account")}>Account</MenuItem>
                                    <MenuItem onClick={() => history.push("?modal=stats")}>Stats</MenuItem>
                                    <MenuItem onClick={() => history.push("?modal=bets")}>Bets</MenuItem>
                                    <MenuItem onClick={() => history.push("?modal=transactions")}>Transactions</MenuItem>
                                    <MenuItem onClick={() => history.push("?modal=seed")}>Seed Pairs</MenuItem>
                                    {(adminStatus.hasAdminCapabilities && adminStatus.token == null) && <MenuItem onClick={() => history.push("?modal=acp-login")} style={{ color: 'orange' }}>ACP</MenuItem>}
                                    {adminStatus.token != null && <MenuItem onClick={() => setAdminStatus({ hasAdminCapabilities: true, token: null })} style={{ color: 'orange' }}>Exit ACP</MenuItem>}
                                    <MenuItem onClick={() => {
                                           fetchAPI("auth/logout").then(() => {
                                            setIsSignedIn(false);
                                     
                                            localStorage.removeItem("authToken");
                                            history.push("/");
                                            webSocket.close(3500);
                                            setWebSocket(null);
                                            setAccountMenuAnchor(null);
                                            setBalance(null);
                                            //          localStorage.removeItem("authToken");
                                            //     setIsSignedIn(false);
                                            //   history.push("/");
                                            //    setAccountMenuAnchor(null);
                                        }).catch(() => {
                                            setIsSignedIn(false);
                                     
                                            localStorage.removeItem("authToken");
                                            history.push("/");
                                            webSocket.close(3500);
                                            setWebSocket(null);
                                            setAccountMenuAnchor(null);
                                            setBalance(null);
                                        });
                                       
                                          //  setBalanceChanged(0);
                                         
                                    }}>Logout</MenuItem>
                                </Menu>
                             {/*   <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="primary-search-account-menu"
                                    aria-haspopup="true"
                                    color="inherit"
                                >
                                    <Chat />
                                </IconButton>*/}
                            </div>
                        </Toolbar>
                    </Container>
                </AppBar>
            </Box>

            {hasCheckedToken && <IgidaDialog open={!isSignedIn} title="Welcome to Kivem Demo Casino!" isClosable={false}><LoginDialog updateSignedInState={(stateSignedIn) => {
                setIsSignedIn(stateSignedIn);
            }} /></IgidaDialog>}

            <IgidaDialog open={useQuery().get("modal") == "account"} title="Account" isClosable={true} handleClose={() => history.push("#")}><AccountDialog updateSignedInState={setIsSignedIn} /></IgidaDialog>
            <IgidaDialog open={useQuery().get("modal") == "stats"} title="Lifetime Statistics" isClosable={true} handleClose={() => history.push("#")}><StatsDialog tokenPrice={currentPrice} /></IgidaDialog>
            <IgidaDialog open={useQuery().get("modal") == "bets"} title="Bets" isClosable={true} handleClose={() => history.push("#")}><BetsDialog /></IgidaDialog>
            <IgidaDialog open={useQuery().get("modal") == "transactions"} title="Transactions" isClosable={true} handleClose={() => history.push("#")}><TransactionsDialog /></IgidaDialog>
            <IgidaDialog open={useQuery().get("modal") == "seed"} title="Seed Pairs" isClosable={true} handleClose={() => history.push("#")}><SeedPairDialog /></IgidaDialog>
            <IgidaDialog open={useQuery().get("modal") == "tos"} title="Terms of Service" isClosable={true} handleClose={() => history.push("#")}><ToSDialog /></IgidaDialog>
            <IgidaDialog open={useQuery().get("modal") == "wallet"} title="Wallet" isClosable={true} handleClose={() => history.push("#")}><WalletDialog /></IgidaDialog>
            <IgidaDialog open={useQuery().get("modal") == "acp-login"} title="ACP Login" isClosable={true} handleClose={() => history.push("#")}><ACPLoginDialog hasAdminCapabilities={adminStatus.hasAdminCapabilities} onLoginToACP={(adminToken) => {
                history.push("#");
                setAdminStatus({ hasAdminCapabilities: true, token: adminToken });
                console.log(adminToken);
            }} /></IgidaDialog>

            <Backdrop style={{ zIndex: 1000 }} open={!hasCheckedToken}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Container fixed style={{ visibility: !isSignedIn ? 'hidden' : '' }}>
                {// <Router>
                }
                {adminStatus.token == null ? <><div>
                    <Switch>
                        <Route exact path="/provablyfair">
                            <ProvablyFair isSignedIn={isSignedIn} />
                        </Route>
                        <Route path="/games/:game">
                            <GameView webSocketConnected={webSocketConnected} balance={balance} tokenPrice={currentPrice} isSignedIn={isSignedIn} />
                        </Route>
                        <Route path="/categories/:category">
                            <CategoryView isSignedIn={isSignedIn} />
                        </Route>
                        <Route exact path="/admin">
                            <AdminPanel token="d" />
                        </Route>
                        <Route exact path="/">
                            <CategoryView isSignedIn={isSignedIn} />
                        </Route>
                        <Route>
                            <WarningIcon style={{ height: 128, width: 128, color: 'orange' }} />
                            <h1>404 - Page not found<br /><Link to="/" style={{ color: '#007bff' }}>Back to homepage</Link></h1>
                        </Route>
                    </Switch>
                </div>
                    <BetsTable tokenPrice={currentPrice} isSignedIn={isSignedIn} /></> : <AdminPanel token={adminStatus.token} />}
            </Container>

            <Divider />

            <Box
                component="footer"
                sx={{
                    py: 3,
                    px: 2,
                    mt: 'auto',
                    backgroundColor: '#0f212e',
                }}
            >  <Container maxWidth="sm">
                    <Typography variant="body1">
                        {/*  Strictly 18+. Gamble Responsibly.*/}
                    </Typography>
                    <Button variant="text" size="medium" onClick={() => history.push("?modal=tos")}>Terms of Service</Button>
                    {/*      <Button variant="text" size="medium">Privacy Policy</Button>
                    <Button variant="text" size="medium">Provably Fair</Button>*/}
                    <br />

                    <Typography variant="body2" color="text.secondary">
                        Copyright © {new Date().getFullYear()} Kivem Demo Casino | All Rights Reserved.
                    </Typography>
                </Container></Box>

        </div>
    );
}