import * as React from 'react';
import Box from '@material-ui/core/Box';

import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/core/Skeleton';

import {
    useParams,
    Link
} from "react-router-dom";


export default function ProvablyFair({ isSignedIn }) {

    return (<> <Box style={{ width: '100%', borderRadius: '5px', backgroundColor: '#213743' }}>
        
        <p style={{padding: 30, paddingTop: 5
        }}>
            <h2>TERMS OF SERVICE</h2>
            <b>END USER AGREEMENT</b>
                <br/>
                <br/>
                This end user agreement (the "Agreement") should be read by you (the "User" or "you") in its entirety prior to your use of Igida’s service or products. Please note that the Agreement constitutes a legally binding agreement between you and Igida (referred to herein as "Igida", "us" or "we") which owns and operates the Internet site found and games described at http://igida.io (the "Service"). By clicking the "I agree" button if and where provided and/or using the Service, you consent to the terms and conditions set forth in this Agreement.
                <br/>
                <br/>
                <b>1. GRANT OF LICENSE</b>
                <br/>
                1.1. Subject to the terms and conditions contained herein, Igida grants the User a non-exclusive, personal, non-transferable right to use the Service on your personal computer or other device that accesses the Internet in order to access the games available and described on the http://igida.io website (the website and games together being the "Service").
                <br/>
                1.2. The Service is not for use by (i) individuals under 18 years of age, (ii) individuals under the legal age of majority in their jurisdiction and (iii) individuals accessing the Service from jurisdictions from which it is illegal to do so. Igida is not able to verify the legality of the Service in each jurisdiction and it is the User's responsibility to ensure that their use of the Service is lawful.
                <br/>
                1.3. Igida and its licensors are the sole holders of all rights in and to the Service and code, structure and organization, including copyright, trade secrets, intellectual property and other rights. You may not, within the limits prescribed by applicable laws: (a) copy, distribute, publish, reverse engineer, decompile, disassemble, modify, or translate the website; or (b) use the Service in a manner prohibited by applicable laws or regulations (each of the above is an "Unauthorized Use"). Igida reserves any and all rights implied or otherwise, which are not expressly granted to the User hereunder and retain all rights, title and interest in and to the Service. You agree that you will be solely liable for any damage, costs or expenses arising out of or in connection with the commission by you of any Unauthorized Use. You shall notify Igida immediately upon becoming aware of the commission by any person of any Unauthorized Use and shall provide Igida with reasonable assistance with any investigations it conducts in light of the information provided by you in this respect.
                <br/>
                1.4. The term "Igida", its domain names and any other trade marks, or service marks used by Igida as part of the Service (the "Trade Marks"), are solely owned by Igida. In addition, all content on the website, including, but not limited to, the images, pictures, graphics, photographs, animations, videos, music, audio and text (the "Site Content") belongs to Igida and is protected by copyright and/or other intellectual property or other rights. You hereby acknowledge that by using the Service, you obtain no rights in the Site Content and/or the Trade Marks, or any part thereof. Under no circumstances may you use the Site Content and/or the Trade Marks without Igida’s prior written consent. Additionally, you agree not to do anything that will harm or potentially harm the rights, including the intellectual property rights of Igida.
                <br/>
                <br/>
                <b>2. NO WARRANTIES</b>
                <br/>
                2.1. Igida DISCLAIMS ANY AND ALL WARRANTIES, EXPRESSED OR IMPLIED, IN CONNECTION WITH THE SERVICE WHICH IS PROVIDED TO YOU "AS IS" AND WE PROVIDE YOU WITH NO WARRANTY OR REPRESENTATION WHATSOEVER REGARDING ITS QUALITY, FITNESS FOR PURPOSE, COMPLETENESS OR ACCURACY.
                <br/>
                2.2. REGARDLESS OF OUR EFFORTS, WE MAKE NO WARRANTY THAT THE SERVICE WILL BE UNINTERRUPTED, TIMELY OR ERROR-FREE, OR THAT DEFECTS WILL BE CORRECTED.
                <br/>
                3. AUTHORITY/TERMS OF SERVICE
                <br/>
                You agree to the game rules described on the http://igida.io website. Igida retains authority over the issuing, maintenance, and closing of the Service. The decision of Igida’s management, as regards any use of the Service, or dispute resolution, is final and shall not be open to review or appeal.
                <br/>
                <br/>
                <b>4. YOUR REPRESENTATIONS AND WARRANTIES</b>
                <br/>
                Prior to your use of the Service and on an ongoing basis you represent, warrant, covenant and agree that:
                <br/>
                4.1. there is a risk of losing tokens when using the Service and that Igida has no responsibility to you for any such loss;
                <br/>
                4.2. your use of the Service is at your sole option, discretion and risk;
                <br/>
                4.3. you are solely responsible for any applicable taxes which may be payable on tokens awarded to you through your using the Service;
                <br/>
                4.5. the telecommunications networks and Internet access services required for you to access and use the Service are entirely beyond the control of Igida and Igida shall have no liability whatsoever for any outages, slowness, capacity constraints or other deficiencies affecting the same; and
                <br/>
                4.6. you are aged 18 or over and that you are not currently self-excluded from any gambling site or gambling premises and that you will inform us immediately if you enter into a self-exclusion agreement with any gambling provider.
                <br/>
                <br/>
                <b>5. PROHIBITED USES</b>
                <br/>
                5.1. PERSONAL USE. The Service is intended solely for the User's personal use. The User is only allowed to wager for his/her personal entertainment.
                <br/>
                5.2. NO SHARED WALLET. The User will not transmit tokens to the address indicated on the website from a shared wallet or any other address not solely controlled by the User as any amounts sent back to the initiating address may not be properly credited to the User.
                <br/>
                5.3. JURISDICTIONS. Persons located in or residents of the United States and the United States Territories (the “Prohibited Jurisdictions”) are not permitted make use of the Service. For the avoidance of doubt, the foregoing restrictions on engaging in real-money play from Prohibited Jurisdictions applies equally to residents and citizens of other nations while located in a Prohibited Jurisdiction. Any attempt to circumvent the restrictions on play by any persons located in a Prohibited Jurisdiction or Restricted Jurisdiction, is a breach of this Agreement. An attempt at circumvention includes, but is not limited to, manipulating the information used by Igida to identify your location and providing Igida with false or misleading information regarding your location or place of residence.
                <br/>
                <br/>
                <b>6. BREACH</b>
                <br/>
                6.1. Without prejudice to any other rights, if a User breaches in whole or in part any provision contained herein, Igida reserves the right to take such action as it sees fit, including terminating this Agreement or any other agreement in place with the User and/or taking legal action against such User.
                <br/>
                6.2. You agree to fully indemnify, defend and hold harmless Igida and its shareholders, directors, agents and employees from and against all claims, demands, liabilities, damages, losses, costs and expenses, including legal fees and any other charges whatsoever, howsoever caused, that may arise as a result of: (i) your breach of this Agreement, in whole or in part; (ii) violation by you of any law or any third party rights; and (iii) use by you of the Service.
                <br/>
                <br/>
                <b>7. LIMITATION OF LIABILITY</b>
                <br/>
                7.1. Under no circumstances, including negligence, shall Igida be liable for any special, incidental, direct, indirect or consequential damages whatsoever (including, without limitation, damages for loss of business profits, business interruption, loss of business information, or any other pecuniary loss) arising out of the use (or misuse) of the Service even if Igida had prior knowledge of the possibility of such damages.
                <br/>
                7.2. Nothing in this Agreement shall exclude or limit Igida’s liability for death or personal injury resulting from its negligence.
                <br/>
                <br/>
                <b>8. DISPUTES</b>
                <br/>
                If a User wishes to make a complaint, please contact our customer service team at support@igida.io. Should any dispute not be resolved to your satisfaction you may pursue remedies in the governing law jurisdiction set forth below.
                <br/>
                <br/>
                <b>9. AMENDMENT</b>
                <br/>
                Igida reserves the right to update or modify this Agreement or any part thereof at any time or otherwise change the Service without notice and you will be bound by such amended Agreement upon posting. Therefore, we encourage you check the terms and conditions contained in the version of the Agreement in force at such time. Your continued use of the Service shall be deemed to attest to your agreement to any amendments to the Agreement.
                <br/>
                <br/>
                <b>10. GOVERNING LAW</b>
                <br/>
                The Agreement and any matters relating hereto shall be governed by, and construed in accordance with, the laws of Costa Rica. You irrevocably agree that, subject as provided below, the courts of Costa Rica shall have exclusive jurisdiction in relation to any claim, dispute or difference concerning the Agreement and any matter arising therefrom and irrevocably waive any right that it may have to object to an action being brought in those courts, or to claim that the action has been brought in an inconvenient forum, or that those courts do not have jurisdiction. Nothing in this clause shall limit the right of Igida to take proceedings against you in any other court of competent jurisdiction, nor shall the taking of proceedings in any one or more jurisdictions preclude the taking of proceedings in any other jurisdictions, whether concurrently or not, to the extent permitted by the law of such other jurisdiction.
                <br/>
                <br/>
                <b>11. SEVERABILITY</b>
                If a provision of this Agreement is or becomes illegal, invalid or unenforceable in any jurisdiction, that shall not affect the validity or enforceability in that jurisdiction of any other provision hereof or the validity or enforceability in other jurisdictions of that or any other provision hereof.
                <br/>
                <br/>
                <b>12. ASSIGNMENT</b>
                <br/>
                Igida reserves the right to assign this agreement, in whole or in part, at any time without notice. The User may not assign any of his/her rights or obligations under this Agreement.
                <br/>
                <br/>
                <b>13. MISCELLANEOUS</b>
                <br/>
                13.1. No waiver by Igida of any breach of any provision of this Agreement (including the failure of Igida to require strict and literal performance of or compliance with any provision of this Agreement) shall in any way be construed as a waiver of any subsequent breach of such provision or of any breach of any other provision of this Agreement.
                <br/>
                13.2. Nothing in this Agreement shall create or confer any rights or other benefits in favour of any third parties not party to this Agreement other than with an affiliate of Igida.
                <br/>
                13.3. Nothing in this Agreement shall create or be deemed to create a partnership, agency, trust arrangement, fiduciary relationship or joint venture between you and us.
                <br/>
                13.4. This Agreement constitutes the entire understanding and agreement between you and us regarding the Service and supersedes any prior agreement, understanding, or arrangement between you and us.</p>
     
        
        </Box></>);
}