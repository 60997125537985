import * as React from 'react';

import PaginatedList from '../PaginatedList.js';

export default function TransactionsDialog() {
    return (  <PaginatedList path={"user/transactions"} keys={{
        timestamp: { type: "DATE", name: "Date" },
        amount: { type: "CURRENCY", name: "Amount" },
        type: { type: "PRETTY_STRING", name: "Type" },
        status: { type: "PRETTY_STRING", name: "Status" }
      }} />);
}