import * as React from 'react';
import { fetchAPI, showToast, formatCurrency, formatMultiplier } from './Utils';

import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';

import Skeleton from '@material-ui/core/Skeleton';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Divider from '@material-ui/core/Divider';

import Fade from '@material-ui/core/Fade';

import Button from '@material-ui/core/Button';

import TabPanel from './TabPanel.js';
import { eventBus } from './EventBus.js';

import { timestampToTime } from './Utils';

import {
    useParams,
    useLocation
} from "react-router-dom";

import {
    matchPath
} from "react-router";
import { SignalCellularNullSharp } from '@material-ui/icons';

const MyBets = ({ isSignedIn, tokenPrice }) => {
    const [bets, setBets] = React.useState(null);

    React.useEffect(() => {
        if (isSignedIn) {
            fetchAPI("bets/self", "GET").then((data) => {
                setBets(data);
            });
        }
    }, [isSignedIn]);

    React.useEffect(() => {
        function eventBusCallback(data) {
            console.log(
                "personal"
            );
            setBets(prevState => ([(data), ...prevState.slice(0, 9)]));
        }

        let thing = eventBus.on("personalBets", eventBusCallback);

        return () => {
            eventBus.remove("personalBets", thing);
        };
    }, []);

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell align="right">Game</TableCell>
                        <TableCell align="right">Time</TableCell>
                        <TableCell align="right">Bet Amount</TableCell>
                        <TableCell align="right">Multiplier</TableCell>
                        <TableCell align="right">Payout</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {bets == null ? [...Array(10)].map((e, i) => <TableRow
                        key={i}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row"><Skeleton /></TableCell>
                        <TableCell align="right"><Skeleton /></TableCell>
                        <TableCell align="right"><Skeleton /></TableCell>
                        <TableCell align="right"><Skeleton /></TableCell>
                        <TableCell align="right"><Skeleton /></TableCell>
                        <TableCell align="right"><Skeleton /></TableCell>
                    </TableRow>) : bets.map((bet) => (
                        <TableRow
                            key={bet.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">{bet.shortId}</TableCell>
                            <TableCell align="right">{bet.gameName}</TableCell>
                            <TableCell align="right">{timestampToTime(bet.timestamp * 1000)}</TableCell>
                            <TableCell align="right">{formatCurrency(bet.amountBet)}</TableCell>
                            <TableCell align="right">{formatMultiplier(bet.multiplier)}</TableCell>
                            <TableCell align="right">{formatCurrency(bet.payout)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

const AllBets = ({ isSignedIn, tokenPrice }) => {
    const [bets, setBets] = React.useState(null);

    React.useEffect(() => {
        if (isSignedIn) {
            fetchAPI("bets/all", "GET").then((data) => {
                setBets(data);
            });
        }
    }, [isSignedIn]);

    React.useEffect(() => {
        function eventBusCallback(data) {
            console.log("all");
            setBets(prevState => ([(data), ...prevState.slice(0, 9)]));
        }

        let thing = eventBus.on("allBets", eventBusCallback);

        return () => {
            eventBus.remove("allBets", thing);
        };
    }, []);

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell align="right">Username</TableCell>
                        <TableCell align="right">Game</TableCell>
                        <TableCell align="right">Time</TableCell>
                        <TableCell align="right">Bet Amount</TableCell>
                        <TableCell align="right">Multiplier</TableCell>
                        <TableCell align="right">Payout</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {bets == null ? [...Array(10)].map((e, i) => <TableRow
                        key={i}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row"><Skeleton /></TableCell>
                        <TableCell align="right"><Skeleton /></TableCell>
                        <TableCell align="right"><Skeleton /></TableCell>
                        <TableCell align="right"><Skeleton /></TableCell>
                        <TableCell align="right"><Skeleton /></TableCell>
                        <TableCell align="right"><Skeleton /></TableCell>
                        <TableCell align="right"><Skeleton /></TableCell>
                    </TableRow>) : bets.map((bet) => (
                        <TableRow
                            key={bet.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">{bet.shortId}</TableCell>
                            <TableCell align="right">{bet.username}</TableCell>
                            <TableCell align="right">{bet.gameName}</TableCell>
                            <TableCell align="right">{timestampToTime(bet.timestamp * 1000)}</TableCell>
                            <TableCell align="right">{formatCurrency(bet.amountBet)}</TableCell>
                            <TableCell align="right">{formatMultiplier(bet.multiplier)}</TableCell>
                            <TableCell align="right">{formatCurrency(bet.payout)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

const ClickToRevealHash = ({ text }) => {
    const [clicked, setClicked] = React.useState(false);

    return (
        <>{clicked ? text : <Button onClick={() => { setClicked(true) }}>Click to reveal</Button>}</>
    );
}

const GameHistory = ({ isSignedIn }) => {
    //let { game } = useParams();
    const { pathname } = useLocation();

    const customUseParams = (path) => {
        const match = matchPath(pathname, { path });
        return match?.params || {}
    }

    let { game } = customUseParams('/games/:game')
    const [history, setHistory] = React.useState(null);

    const fetchHistory = () => {
        fetchAPI("games/" + game + "/history", "GET").then((data) => {
            setHistory(data);
        });
    }

    React.useEffect(() => {
        if (isSignedIn && game != null) {
            fetchHistory();
        }
    }, [isSignedIn]);

    React.useEffect(() => {
        if (isSignedIn && game != null) {
            fetchHistory();
        }
    }, [game]);

    React.useEffect(() => {
        function eventBusCallback(data) {
            setHistory(prevState => ([(data), ...prevState.slice(0, 9)]));
        }

        let thing = eventBus.on("gameHistory", eventBusCallback);

        return () => {
            eventBus.remove("gameHistory", thing);
        };
    }, []);

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Time</TableCell>
                        <TableCell align="right">Result</TableCell>
                        <TableCell align="right">Hash</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {history == null ? [...Array(10)].map((e, i) => <TableRow
                        key={i}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row"><Skeleton /></TableCell>
                        <TableCell align="right"><Skeleton /></TableCell>
                        <TableCell align="right"><Skeleton /></TableCell>
                    </TableRow>) : history.map((historicalGame, i) => (
                        <TableRow
                            key={i}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell>{timestampToTime(historicalGame.timestamp * 1000)}</TableCell>
                            <TableCell align="right">{historicalGame.result}</TableCell>
                            {//<Button onClick={() => {return; navigator.clipboard.writeText(historicalGame.hash); showToast("success", "Hash " + historicalGame.hash + " copied to clipboard!") }}>Copy</Button>

                                //          <TableCell align="right">{historicalGame.hash}</TableCell>
                            }
                            <TableCell align="right"><ClickToRevealHash text={historicalGame.hash} /></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

/*
function customUseParams(path) {
    const { pathname } = useLocation();
    const match = matchPath(pathname, { path });
    return match?.params || {}
}
*/
export default function BetsTable({ isSignedIn, tokenPrice }) {
    // let { game } = useParams();

    const { pathname } = useLocation();

    const customUseParams = (path) => {
        const match = matchPath(pathname, { path });
        return match?.params || {}
    }
    let { game } = customUseParams('/games/:game')
    const [tab, setTab] = React.useState(0);

    React.useEffect(() => {
        if (game == null) {
            setTab(0);
        }
    }, [game]);

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tab} variant="scrollable" allowScrollButtonsMobile onChange={(e, index) => setTab(index)} aria-label="basic tabs example">
                    <Tab label="My Bets" />
                    <Tab label="All Bets" />
                    {(game == "crash" || game == "stack" || game == "crash-volatile") && <Tab label="Game History" style={{ marginLeft: 'auto' }} />}
                </Tabs>
            </Box>
            <TabPanel value={tab} index={0} style={{ padding: 0 }}>
                <MyBets tokenPrice={tokenPrice} isSignedIn={isSignedIn} />
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <AllBets tokenPrice={tokenPrice} isSignedIn={isSignedIn} />
            </TabPanel>
            <TabPanel value={tab} index={2}>
                <GameHistory isSignedIn={isSignedIn} />
            </TabPanel>
        </>
    );
}