import * as React from 'react';
import { showToast, fetchAPI, formatCurrency } from '../Utils.js';

import Skeleton from '@material-ui/core/Skeleton';

import Paper from '@material-ui/core/Paper';
import Alert from '@material-ui/core/Alert';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';

import Typography from '@material-ui/core/Typography';

import {
    useHistory,
} from "react-router-dom";

function Cell({ data, isCurrency }) {
    return (
        data == null ? 
            <TableCell align="right"><CircularProgress size={30} /></TableCell>
         :
            <TableCell align="right">
              {//  {tokenPrice === null ? data : (localStorage.balanceDisplayCurrency == "USD" ? "$" : "") + (translateAmountToCurrency(data, tokenPrice) + "") + (localStorage.balanceDisplayCurrency != "USD" ? " IGIDA" : "")}
}
            {isCurrency ? formatCurrency(data) : data}
           </TableCell>
    );
}

export default function StatsDialog() {
    const history = useHistory();
    const [stats, setStats] = React.useState({ betsMade: null, betsLost: null, betsWon: null, wagered: null, amountWon: null, amountLost: null });

    React.useEffect(() => {
        fetchAPI("user/stats", "GET", null, true).then((data) => {
            setStats(data);
        }).catch(() => {
           setStats({betsMade: 0, betsLost: 0, betsWon: 0, wagered: 0, amountWon: 0, amountLost: 0});
            // showToast("error", "Your statistics are currently not available. Make a few bets and try again!");
          //  history.push("#");
        });
    }, []);

    return (
        <>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableBody>

                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">Bets Made</TableCell>
                            <Cell data={stats.betsMade} />
                        </TableRow>

                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">Bets Lost</TableCell>
                            <Cell data={stats.betsLost} />
                        </TableRow>

                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">Bets Won</TableCell>
                            <Cell data={stats.betsWon} />
                        </TableRow>

                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">Wagered</TableCell>
                            <Cell data={stats.wagered} isCurrency />
                        </TableRow>

                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">Amount Won</TableCell>
                            <Cell data={stats.amountWon} isCurrency />
                        </TableRow>

                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">Amount Lost</TableCell>
                            <Cell data={stats.amountLost} isCurrency />
                        </TableRow>

                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}