import * as React from 'react';
import { showToast, fetchAPI, CURRENCY_DENOMINATOR } from '../Utils.js';


import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import LoadingButton from '@material-ui/lab/LoadingButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Divider from '@material-ui/core/Divider';

import CircularProgress from '@material-ui/core/CircularProgress';

import TabPanel from '../TabPanel.js';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import { makeString } from '../Utils.js';

import HCaptcha from '@hcaptcha/react-hcaptcha';

import {
    useHistory
} from "react-router-dom";

const BonusCodeTab = () => {
    const history = useHistory();
    const [isPerformingAction, setIsPerformingAction] = React.useState(false);
    const [bonusCode, setBonusCode] = React.useState("");

    const captchaRef = React.useRef();

    const redeemCode = async () => {
        setIsPerformingAction(true);

        captchaRef.current.execute({ async: true }).then(({ response }) => {
            fetchAPI("campaign/redeem", "POST", { redeemCode: bonusCode, captchaToken: response }).then((data) => {
                showToast("success", "Claimed $" + data.amount + " from bonus code!");
                history.push("#");
            }).finally(() => {
                setIsPerformingAction(false);
                captchaRef.current.resetCaptcha();
                //setBonusCode("");
            })
        }).catch(() => {
            setIsPerformingAction(false);
        });
    }

    return (
        <FormControl sx={{ width: '100%' }}>
            <TextField sx={{ m: 1 }} margin="dense" id="outlined-basic" label="Bonus Code" variant="filled" value={bonusCode} onChange={(e) => setBonusCode(e.target.value)} />
            <HCaptcha
                size="invisible"
                sitekey="df02adc2-de53-4a5f-a77c-22fa12ba8e42"
                ref={captchaRef}
                onVerify={() => undefined}
            />
            <LoadingButton loading={isPerformingAction} sx={{ m: 1 }} variant="contained" onClick={() => {
                redeemCode();
            }}>Claim</LoadingButton>
        </FormControl>
    );
}

const SendTipTab = () => {
    const [isPerformingAction, setIsPerformingAction] = React.useState(false);
    const [tipInfo, setTipInfo] = React.useState({ toUsername: "", amount: 0, twoFactorAuthCode: 0, showInChat: false });

    const sendTip = () => {
        setIsPerformingAction(true);

        fetchAPI("wallet/tip", "POST", tipInfo).then((data) => {
            showToast("success", "Your tip to $" + tipInfo.toUsername + " for " + tipInfo.amount + " has been sent!");
        }).finally(() => {
            setIsPerformingAction(false);
        })
    }

    return (
        <FormControl style={{ width: '100%' }}>
            <TextField margin="dense" id="outlined-basic" label="Username" variant="filled" onChange={(e) => setTipInfo(prevState => ({...prevState, toUsername: e.target.value}))} sx={{ m: 1 }}
            />
            <TextField
                id="outlined-number"
                type="number"
                sx={{ m: 1 }}
                InputProps={{
                    startAdornment: <InputAdornment position="start">{CURRENCY_DENOMINATOR}</InputAdornment>,
                }}
                label="Tip Amount"
                variant="filled"
                onChange={(e) => setTipInfo(prevState => ({...prevState, amount: parseFloat(e.target.value)}))}
            />
            <TextField sx={{ m: 1 }} type="number" margin="dense" id="outlined-basic" label="2-FA Code (if enabled)" variant="filled"                 onChange={(e) => setTipInfo(prevState => ({...prevState, twoFactorAuth: parseInt(e.target.value)}))} />
            <FormControlLabel sx={{ m: 0 }} control={<Checkbox />} onChange={(e) => setTipInfo(prevState => ({ ...prevState, showInChat: e.target.checked }))} label="Show publicly in chat" />
            <LoadingButton loading={isPerformingAction} onClick={() => sendTip()} variant="contained" sx={{ m: 1 }}
            >Send Tip</LoadingButton>
        </FormControl>
    );
}

export default function WalletDialog() {
    const history = useHistory();

    const [isPerformingAction, setIsPerformingAction] = React.useState(false);

    const [bonusCode, setBonusCode] = React.useState("");

    const [tab, setTab] = React.useState(0);

    return (<>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tab} variant="scrollable" allowScrollButtonsMobile onChange={(e, index) => setTab(index)}>
            {/*    <Tab label="Deposit" />
                <Tab label="Withdraw" />*/}
                <Tab label="Tip" />
                <Tab label="Bonus Code" />
            </Tabs>
        </Box>
        <TabPanel value={tab} index={-5}>
            <FormControl style={{ width: '100%' }}>
                <TextField
                    id="outlined-number"
                    type="number"
                    sx={{ m: 1 }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">{CURRENCY_DENOMINATOR}</InputAdornment>,
                    }}
                    label="Deposit Amount"
                    variant="filled"
                />
                <LoadingButton loading={isPerformingAction} variant="contained" sx={{ m: 1 }}
                >Deposit</LoadingButton>
            </FormControl>
        </TabPanel>
        <TabPanel value={tab} index={-5}>
            <FormControl style={{ width: '100%' }}>
                <TextField
                    id="outlined-number"
                    type="number"
                    sx={{ m: 1 }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">{CURRENCY_DENOMINATOR}</InputAdornment>,
                    }}
                    label="Withdrawal Amount"
                    variant="filled"
                />
                <LoadingButton loading={isPerformingAction} variant="contained" sx={{ m: 1 }}
                >Withdraw</LoadingButton>
            </FormControl>
        </TabPanel>
        <TabPanel value={tab} index={0}>
            <SendTipTab/>
        </TabPanel>
        <TabPanel value={tab} index={1}>
            <BonusCodeTab />
        </TabPanel>
    </>);
}