import * as React from 'react';
import { showToast, fetchAPI } from '../Utils.js';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import LoadingButton from '@material-ui/lab/LoadingButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import TabPanel from '../TabPanel.js';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import {
  Link
} from "react-router-dom";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min.js';

export default function ACPLoginDialog({ hasAdminCapabilities, onLoginToACP }) {
  const history = useHistory();
    const [isPerformingAction, setIsPerformingAction] = React.useState(false);
  
    const LoginTab = () => {
      const [loginInfo, setLoginInfo] = React.useState({ username: "", password: "", twoFactorAuthCode: 0 });
  
      const performLogin = () => {
        let fixedLoginInfo = { ...loginInfo, twoFactorAuthCode: (isNaN(loginInfo.twoFactorAuthCode) ? 0 : parseInt(loginInfo.twoFactorAuthCode)) };
  
        fetchAPI("admin/login", "POST", fixedLoginInfo, true).then((data) => {
          onLoginToACP(data.token);
          showToast("success", "Logged in to ACP successfully!");
          history.push("/");
          setIsPerformingAction(false);
        }).catch(() => {
          setIsPerformingAction(false);
        });
      }
  
      return (
        <FormControl style={{width: '100%'}}>
          <TextField type="number" margin="dense" id="outlined-basic" label="2-FA Code" variant="filled" onChange={(e) => setLoginInfo(prevState => ({ ...prevState, twoFactorAuthCode: e.target.value }))} />
          <LoadingButton disabled={!hasAdminCapabilities} loading={isPerformingAction} style={{ marginTop: 10 }} variant="contained" onClick={() => {
            setIsPerformingAction(true);
            performLogin();
          }}>{hasAdminCapabilities ? "Log in" : "No admin capabilities"}</LoadingButton>
        </FormControl>);
    }
  
  
    return (
      <>
  
          <LoginTab />

      </>
    );
  }