import * as React from 'react';

import Link from '@material-ui/core/Link';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import {
    useParams
} from "react-router-dom";


export default function BetAmountBox({ disabled, balance, value, onChange }) {
    const [betAmount, setBetAmount] = React.useState(value);

    React.useEffect(() => {
        setBetAmount(value);
    }, [value]);

    return (<><TextField
        id="outlined-number"
        type="number"
        error={betAmount > balance && !disabled}
        helperText={(betAmount > balance && !disabled) && "Can't bet more than your balance!"}
        disabled={disabled}
        value={betAmount}
        onChange={(event) => {
            setBetAmount(event.target.value);
            onChange(event.target.value);
        }}
        sx={{ m: 1, width: '75%' }}
        InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
           // endAdornment: <InputAdornment position="start">β</InputAdornment>,
        }}
        variant="filled"
        label="Bet Amount"
    /></>);
}