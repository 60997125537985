import * as React from 'react';

import PaginatedList from '../PaginatedList.js';

export default function BetsDialog() {
    return (   <PaginatedList path={"user/bets"} keys={{
        gameName: { type: "STRING", name: "Game" },
        shortId: { type: "STRING", name: "Id" },
        timestamp: { type: "DATE", name: "Date" },
        amountBet: { type: "CURRENCY", name: "Bet Amount" },
        multiplier: { type: "MULTIPLIER", name: "Multiplier" },
        payout: { type: "CURRENCY", name: "Payout" }
          }} />);
}