import * as React from 'react';

import {
  useParams
} from "react-router-dom";

import InputAdornment from '@material-ui/core/InputAdornment';


import { useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { tabPanelClasses } from '@material-ui/lab';
import { fetchAPI, timestampToDate, showToast, formatCurrency, formatMultiplier } from './Utils';

import TabPanel from './TabPanel.js';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import LoadingButton from '@material-ui/lab/LoadingButton';


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Pagination from '@material-ui/core/Pagination';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import IgidaDialog from './modals/IgidaDialog.js';
import TextDialog from './modals/TextDialog.js';

const PaginatedList = ({ keys, path, token, onNeedExploreDetailed, body, useGet }) => {
  const [items, setItems] = React.useState([]);
  const [pageInfo, setPageInfo] = React.useState({ currentPage: 0, totalPages: 0 });

  function fetchItems(page) {
    fetchAPI("admin/" + path, useGet ? "GET" : "POST", useGet ? null : { ...body, skip: page * 20 }, true, token).then((data) => {
      setItems(data.data);
      setPageInfo({ currentPage: page, totalPages: Math.ceil(data.totalDocuments / 20) });
    });
  }

  React.useEffect(() => {
    fetchItems(0);
  }, [path]);

  /*React.useEffect(() => {
    fetchItems(0);
  }, [path]);
*/
  const parse = (type, value) => {
    //console.log(type + " " + value);
    switch (type) {
      case "STR_ARRAY": {
        return JSON.stringify(value);
      }
      case "STRING": {
        return value;
      }
      case "CURRENCY": {
        return formatCurrency(value);
      }
      case "DATE": {
        return timestampToDate(value * 1000);
      }
      case "ARR_LENGTH": {
        return value.length;
      }
      case "BOOLEAN": {
        return value ? "true" : "false";
      }
      case "DETAILED_VIEW": {
        return <Button size="medium" onClick={() => onNeedExploreDetailed(typeof value === 'object' ? JSON.stringify(value, null, 4) : value)}>View</Button>;
      }
      case "MULTIPLIER": {
        return formatMultiplier(value);
      }
      case "PRETTY_STRING": {
        return value.replaceAll("_", " ").toLowerCase().replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase());
      }
      default: {
        return value;
      }
    }
  }

  const refresh = () => {
    fetchItems(pageInfo.currentPage);
  }

  function getManageMenu(actions, item) {
    const [menuAnchor, setMenuAnchor] = React.useState(null);
    // console.log(item);
    return (<>
      <Button
        size="medium"
        onClick={(e) => setMenuAnchor(e.currentTarget)}
      >
        Manage
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={menuAnchor}
        open={menuAnchor != null}
        onClose={() => setMenuAnchor(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {actions.map((action, i) => (
          <MenuItem onClick={() => action.onClick(item, refresh)}>{action.name}</MenuItem>
        ))}
      </Menu></>
    );
  }

  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {Object.keys(keys).map((key, i) => (
                <><TableCell key={i} align={i > 0 ? "right" : ""}>{key == "_manage" ? "Manage" : keys[key].name}</TableCell></>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, i) => (
              <TableRow
                key={i}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                {Object.keys(keys).map((key, _i) => (
                  <><TableCell key={_i} align={_i > 0 ? "right" : ""} component={_i > 0 ? "th" : ""} scope={_i > 0 ? "row" : ""}>{key == "_manage" ? getManageMenu(keys[key], item) : parse(keys[key].type, item[key])}</TableCell></>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Pagination count={pageInfo.totalPages} page={pageInfo.currentPage + 1} onChange={(e, v) => fetchItems(v - 1)} />
      </div>
    </div>
  );
}

const CreateCampaign = ({ token }) => {
  const [campaignInfo, setCampaignInfo] = React.useState({});
  const [isPerformingAction, setIsPerformingAction] = React.useState(false);

  const addCampaign = () => {
    setIsPerformingAction(true);

    fetchAPI("admin/campaigns/create", "POST", campaignInfo, true, token).then((data) => {
      showToast("success", "Added campaign successfully!");
      setIsPerformingAction(false);
    }).catch(() => {
      setIsPerformingAction(false);
    });
  }

  return (
    <FormControl style={{ width: '100%' }}>
      <TextField margin="dense" id="outlined-basic" label="Code" variant="filled" onChange={(e) => setCampaignInfo(prevState => ({ ...prevState, code: e.target.value }))} />
      <TextField margin="dense" id="outlined-basic" label="Amount" type="number" variant="filled" onChange={(e) => setCampaignInfo(prevState => ({ ...prevState, amount: parseFloat(e.target.value) }))} />
      <TextField
        margin="dense"
        label="Start"
        type="datetime-local"
        onChange={(event, x) => setCampaignInfo(prevState => ({ ...prevState, startTime: new Date(event.target.value).getTime() / 1000 }))}
        sx={{ width: 250 }}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        margin="dense"
        label="End"
        type="datetime-local"
        sx={{ width: 250 }}
        onChange={(event, x) => setCampaignInfo(prevState => ({ ...prevState, endTime: new Date(event.target.value).getTime() / 1000 }))}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField margin="dense" id="outlined-basic" label="Max redeems" type="number" variant="filled" onChange={(e) => setCampaignInfo(prevState => ({ ...prevState, maxRedeems: parseInt(e.target.value) }))} />

      <LoadingButton loading={isPerformingAction} style={{ marginTop: 10 }} variant="contained" onClick={() => {
        setIsPerformingAction(true);
        addCampaign();
      }}>Add Campaign</LoadingButton>
    </FormControl>);
}

const CreateChallenge = ({ token }) => {
  const [challengeInfo, setChallengeInfo] = React.useState({});
  const [isPerformingAction, setIsPerformingAction] = React.useState(false);

  const addChallenge = () => {
    setIsPerformingAction(true);

    fetchAPI("admin/challenges/create", "POST", challengeInfo, true, token).then((data) => {
      showToast("success", "Added challenge successfully!");
      setIsPerformingAction(false);
    }).catch(() => {
      setIsPerformingAction(false);
    });
  }

  return (
    <FormControl style={{ width: '100%' }}>
      <TextField margin="dense" id="outlined-basic" label="Game Name" variant="filled" onChange={(e) => setChallengeInfo(prevState => ({ ...prevState, gameName: e.target.value }))} />
      <TextField margin="dense" id="outlined-basic" label="Min Bet Amount" type="number" variant="filled" onChange={(e) => setChallengeInfo(prevState => ({ ...prevState, minBetAmount: parseFloat(e.target.value) }))} />
      <TextField margin="dense" id="outlined-basic" label="Min Multiplier" type="number" variant="filled" onChange={(e) => setChallengeInfo(prevState => ({ ...prevState, minMultiplier: parseFloat(e.target.value) }))} />
      <TextField margin="dense" id="outlined-basic" label="Prize" type="number" variant="filled" onChange={(e) => setChallengeInfo(prevState => ({ ...prevState, prize: parseFloat(e.target.value) }))} />
      <TextField
        margin="dense"
        label="Start"
        type="datetime-local"
        onChange={(event, x) => setChallengeInfo(prevState => ({ ...prevState, startTime: new Date(event.target.value).getTime() / 1000 }))}
        sx={{ width: 250 }}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        margin="dense"
        label="End"
        type="datetime-local"
        sx={{ width: 250 }}
        onChange={(event, x) => setChallengeInfo(prevState => ({ ...prevState, endTime: new Date(event.target.value).getTime() / 1000 }))}
        InputLabelProps={{
          shrink: true,
        }}
      />

      <LoadingButton loading={isPerformingAction} style={{ marginTop: 10 }} variant="contained" onClick={() => {
        setIsPerformingAction(true);
        addChallenge();
      }}>Add Challenge</LoadingButton>
    </FormControl>);
}

const BetLookup = ({ token, onNeedExploreDetailed }) => {
  const [id, setId] = React.useState("");
  const [finalId, setFinalId] = React.useState("");

  return (<>
    <FormControl style={{ width: '100%' }}>
      <TextField margin="dense" id="outlined-basic" label="Bet Id/Short Id" value={id} variant="filled" onChange={(e) => setId(e.target.value)} />

      <LoadingButton style={{ marginTop: 10 }} variant="contained" onClick={() => {
        setFinalId(id);
      }}>Search</LoadingButton>
    </FormControl>
    {finalId != "" &&
     <PaginatedList path={"bets/" + finalId + "/info"} token={token} useGet onNeedExploreDetailed={onNeedExploreDetailed} keys={{
      id: { type: "STRING", name: "Id" },
      gameName: { type: "STRING", name: "Game" },
      shortId: { type: "STRING", name: "Short Id" },
      username: { type: "STRING", name: "Username" },
      userId: { type: "STRING", name: "User Id" },
      timestamp: { type: "DATE", name: "Time" },
      amountBet: { type: "CURRENCY", name: "Bet Amount" },
      multiplier: { type: "MULTIPLIER", name: "Multiplier" },
      payout: { type: "CURRENCY", name: "Payout" },
      note: { type: "DETAILED_VIEW", name: "Note" }
    }} />}</>
    );
}

const UserManageModal = ({modal, token, onNeedExploreDetailed}) => {
  switch(modal.type) {
    case "TRANSACTIONS": {
      return (
        <PaginatedList path={"users/" + modal.userId + "/transactions"} onNeedExploreDetailed={onNeedExploreDetailed} token={token} keys={{
          id: { type: "STRING", name: "Id" },
          timestamp: { type: "DATE", name: "Date" },
          otherParty: { type: "STRING", name: "Other Party" },
          amount: { type: "CURRENCY", name: "Amount" },
          type: { type: "PRETTY_STRING", name: "Type" },
          data: { type: "DETAILED_VIEW", name: "Data" },
          status: { type: "String", name: "Status" }
        }} />
      );

      break;
    }
    case "BETS": {
      return (
        <PaginatedList path={"users/" + modal.userId + "/bets"} token={token} onNeedExploreDetailed={onNeedExploreDetailed} keys={{
          id: { type: "STRING", name: "Id" },
          gameName: { type: "STRING", name: "Game" },
          shortId: { type: "STRING", name: "Short Id" },
          username: { type: "STRING", name: "Username" },
          userId: { type: "STRING", name: "User Id" },
          timestamp: { type: "DATE", name: "Time" },
          amountBet: { type: "CURRENCY", name: "Bet Amount" },
          multiplier: { type: "MULTIPLIER", name: "Multiplier" },
          payout: { type: "CURRENCY", name: "Payout" },
          note: { type: "DETAILED_VIEW", name: "Note" }
        }} />
      );

      break;
    }
    case "SESSIONS": {
      return (
        <PaginatedList path={"users/" + modal.userId + "/sessions"} token={token} useGet onNeedExploreDetailed={onNeedExploreDetailed} keys={{
          id: { type: "STRING", name: "Id" },
          userId: { type: "STRING", name: "User Id" },
          gameRoundId: { type: "STRING", name: "Game Round Id" },
          game: { type: "STRING", name: "Game" },
          startTimestamp: { type: "DATE", name: "Date" },
          bets: { type: "DETAILED_VIEW", name: "Bets" },
          state: { type: "DETAILED_VIEW", name: "State" },
          _manage: [{
            name: "Void", onClick: (item, refresh) => {
              fetchAPI("admin/sessions/" + item.id + "/void", "GET", {}, true, token).then(() => {
                showToast("success", "Session voided!");
                refresh();
              });
            }
          },
          {
            name: "Remove", onClick: (item, refresh) => {
              fetchAPI("admin/sessions/" + item.id + "/remove", "GET", {}, true, token).then(() => {
                showToast("success", "Session removed!");
                refresh();
              });
            }
          }]
        }} />
      );

      break;
    }
    case "UPDATE_BALANCE": {
      const [change, setChange] = React.useState(0);
      const [isBusy, setIsBusy] = React.useState(false);

      return (
        <FormControl style={{ width: '100%' }}>
        <TextField margin="dense" label="Change" type="number" value={change} variant="filled" onChange={(e) => {
          //console.log(e.target.value);
          setChange(!/^\d+(\.\d+)?/.exec(e.target.value) ? 0 : parseFloat(e.target.value))
        }} />
  
        <LoadingButton disabled={isBusy} style={{ marginTop: 10 }} variant="contained" onClick={() => {
          setIsBusy(true);

          fetchAPI("admin/users/" + modal.userId + "/updateBalance", "POST", {change: change}, true, token).then(() => {
            setIsBusy(false);
            showToast("success", "Balance updated!");
        //    setFinalId("");
          //  setFinalId(id);
          });
        }}>Change Balance</LoadingButton>
      </FormControl>
      );

      break;
    }
    case "UPDATE_ROLES": {
      const availableRoles = ["USER", "RESTRICTED_WALLET", "RESTRICTED_GAMEPLAY", "ADMIN", "MOD", "BANNED", "DELETED"];

      const [roles, setRoles] = React.useState(modal.roles);
      const [isBusy, setIsBusy] = React.useState(false);

      const modifyRole = (role) => {
        if(roles.includes(role))
        {
          setRoles(prevState => (prevState.filter(function(_role) {
            return role !== _role;
          })));
        } else {
          setRoles(prevState => ([...prevState, role]));
        }
      }

      return (
        <FormControl style={{ width: '100%' }}>
          {availableRoles.map((role, i) => (
            <FormControlLabel key={i} control={<Checkbox checked={roles.includes(role)} />} onChange={(e) => modifyRole(role)} label={<p>{role}</p>} />
          ))}
  
        <LoadingButton disabled={isBusy} style={{ marginTop: 10 }} variant="contained" onClick={() => {
          setIsBusy(true);

          fetchAPI("admin/users/" + modal.userId + "/updateRoles", "POST", {roles: roles}, true, token).then(() => {
            setIsBusy(false);
            showToast("success", "Roles updated!");
       //     setFinalId("");
         //   setFinalId(id);
          });
        }}>Update Roles</LoadingButton>
      </FormControl>
      );

      break;
    }
  }
}

const AllUsers = ({ token, onNeedExploreDetailed }) => {
  const [modal, setModal] = React.useState({type: null, userId: null, roles: []});

  return (<>
      <IgidaDialog open={modal.type != null} title="Manage User" isClosable={true} handleClose={() => setModal({type: null, userId: null, roles: []})}><UserManageModal token={token} modal={modal} onNeedExploreDetailed={onNeedExploreDetailed} /></IgidaDialog>
   
     <PaginatedList path={"listUsers"} token={token} onNeedExploreDetailed={onNeedExploreDetailed} keys={{
      id: { type: "STRING", name: "Id" },
      username: { type: "STRING", name: "Username" },
      balance: { type: "CURRENCY", name: "Balance" },
      registrationTimestamp: { type: "DATE", name: "Registration date" },
      twoFactorAuth: { type: "DETAILED_VIEW", name: "2FA" },
      fairPairs: { type: "DETAILED_VIEW", name: "Fair pairs" },
      lastIAT: { type: "DATE", name: "Last sign in" },
      roles: { type: "DETAILED_VIEW", name: "Roles" },
      _manage: [{name: "Refresh Account", onClick: (item, refresh) => { fetchAPI("admin/users/" + item.id + "/refreshAccount", "GET", null, true, token).then(() => {
        showToast("success", "User successfully refreshed!");
        refresh();
      }); }},{name: "Update Roles", onClick: (item, refresh) => { setModal({type: "UPDATE_ROLES", userId: item.id, roles: item.roles}); }}, {name: "Update Balance", onClick: (item, refresh) => {setModal({type: "UPDATE_BALANCE", userId: item.id})}}, {name: "View Sessions", onClick: (item, refresh) => {setModal({type: "SESSIONS", userId: item.id})}},{name: "View Transactions", onClick: (item, refresh) => {setModal({type: "TRANSACTIONS", userId: item.id})}}, {name: "View Bets", onClick: (item, refresh) => {setModal({type: "BETS", userId: item.id})}}]
    }} /></>
    );
}


const UserLookup = ({ token, onNeedExploreDetailed }) => {
  const [id, setId] = React.useState("");
  const [finalId, setFinalId] = React.useState("");
  const [modal, setModal] = React.useState({type: null, userId: null, roles: []});

  return (<>
      <IgidaDialog open={modal.type != null} title="Manage User" isClosable={true} handleClose={() => setModal({type: null, userId: null, roles: []})}><UserManageModal token={token} modal={modal} onNeedExploreDetailed={onNeedExploreDetailed} /></IgidaDialog>

    <FormControl style={{ width: '100%' }}>
      <TextField margin="dense" id="outlined-basic" label="Criteria" value={id} variant="filled" onChange={(e) => setId(e.target.value)} />

      <LoadingButton style={{ marginTop: 10 }} variant="contained" onClick={() => {
        setFinalId(id);
      }}>Search</LoadingButton>
    </FormControl>
    {finalId != "" &&
     <PaginatedList path={"findUser/" + finalId} token={token} useGet onNeedExploreDetailed={onNeedExploreDetailed} keys={{
      id: { type: "STRING", name: "Id" },
      username: { type: "STRING", name: "Username" },
      balance: { type: "CURRENCY", name: "Balance" },
      registrationTimestamp: { type: "DATE", name: "Registration date" },
      twoFactorAuth: { type: "DETAILED_VIEW", name: "2FA" },
      fairPairs: { type: "DETAILED_VIEW", name: "Fair pairs" },
      lastIAT: { type: "DATE", name: "Last sign in" },
      roles: { type: "DETAILED_VIEW", name: "Roles" },
      _manage: [{name: "Refresh Account", onClick: (item, refresh) => { fetchAPI("admin/users/" + item.id + "/refreshAccount", "GET", null, true, token).then(() => {
        showToast("success", "User successfully refreshed!");
        refresh();
      }); }},{name: "Update Roles", onClick: (item, refresh) => { setModal({type: "UPDATE_ROLES", userId: item.id, roles: item.roles}); }}, {name: "Update Balance", onClick: (item, refresh) => {setModal({type: "UPDATE_BALANCE", userId: item.id})}}, {name: "View Sessions", onClick: (item, refresh) => {setModal({type: "SESSIONS", userId: item.id})}},{name: "View Transactions", onClick: (item, refresh) => {setModal({type: "TRANSACTIONS", userId: item.id})}}, {name: "View Bets", onClick: (item, refresh) => {setModal({type: "BETS", userId: item.id})}}]
    }} />}</>
    );
}

export default function AdminPanel({ token }) {
  const [tab, setTab] = React.useState(0);
  const [detailedViewText, setDetailedViewText] = React.useState(null);

  return (<>
    <h2>Admin Control Panel</h2>

    <IgidaDialog open={detailedViewText != null} title="Detailed Text View" isClosable={true} handleClose={() => setDetailedViewText(null)}><TextDialog text={detailedViewText} /></IgidaDialog>

    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={tab} variant="scrollable" allowScrollButtonsMobile onChange={(e, index) => setTab(index)}>
       {/* <Tab label="Overview " />
        <Tab label="Games " />*/}
        <Tab label="User Lookup" />
        <Tab label="All Users" />
        <Tab label="List Challenges" />
        <Tab label="Create Challenge" />
        <Tab label="List Campaigns" />
        <Tab label="Create Campaign" />
        <Tab label="All Sessions" />
        <Tab label="All Bets" />
        <Tab label="All Logs" />
        <Tab label="All Transactions" />
        <Tab label="Bet Lookup" />
      </Tabs>
    </Box>
    <TabPanel value={tab} index={4}>
      <PaginatedList path={"campaigns/list"} token={token} keys={{
        code: { type: "STRING", name: "Code" },
        active: { type: "BOOLEAN", name: "Active" },
        amount: { type: "CURRENCY", name: "Amount" },
        startTime: { type: "DATE", name: "Start Date" },
        endTime: { type: "DATE", name: "End Date" },
        maxRedeems: { type: "STRING", name: "Max Redeems" },
        redeemed: { type: "ARR_LENGTH", name: "Redeemed" },
        _manage: [{
          name: "Deactivate", onClick: (item, refresh) => {
            fetchAPI("admin/campaigns/" + item.code + "/deactivate", "GET", null, true, token).then(() => {
              showToast("success", "Campaign deactivated!");
              refresh();
            });
          }
        }]
      }} />

    </TabPanel>
    <TabPanel value={tab} index={6}>
      <PaginatedList path={"listNonLiveSessions"} token={token} onNeedExploreDetailed={(text) => setDetailedViewText(text)} keys={{
        id: { type: "STRING", name: "Id" },
        userId: { type: "STRING", name: "User Id" },
        gameRoundId: { type: "STRING", name: "Game Round Id" },
        game: { type: "STRING", name: "Game" },
        startTimestamp: { type: "DATE", name: "Date" },
        bets: { type: "DETAILED_VIEW", name: "Bets" },
        state: { type: "DETAILED_VIEW", name: "State" },
        _manage: [{
          name: "Void", onClick: (item, refresh) => {
            fetchAPI("admin/sessions/" + item.id + "/void", "GET", {}, true, token).then(() => {
              showToast("success", "Session voided!");
              refresh();
            });
          }
        },
        {
          name: "Remove", onClick: (item, refresh) => {
            fetchAPI("admin/sessions/" + item.id + "/remove", "GET", {}, true, token).then(() => {
              showToast("success", "Session removed!");
              refresh();
            });
          }
        }]
      }} />
    </TabPanel>
    <TabPanel value={tab} index={5}>
      <CreateCampaign token={token} />
    </TabPanel>
    <TabPanel value={tab} index={7}>
    <PaginatedList path={"listBets"} token={token} onNeedExploreDetailed={(text) => setDetailedViewText(text)} keys={{
      id: { type: "STRING", name: "Id" },
      gameName: { type: "STRING", name: "Game" },
      shortId: { type: "STRING", name: "Short Id" },
      username: { type: "STRING", name: "Username" },
      userId: { type: "STRING", name: "User Id" },
      timestamp: { type: "DATE", name: "Time" },
      amountBet: { type: "CURRENCY", name: "Bet Amount" },
      multiplier: { type: "MULTIPLIER", name: "Multiplier" },
      payout: { type: "CURRENCY", name: "Payout" },
      note: { type: "DETAILED_VIEW", name: "Note" }
    }} />
      
      {//<ListBets token={token} />
}
    </TabPanel>
    <TabPanel value={tab} index={8}>
      <PaginatedList path={"logs"} body={{ tags: [] }} token={token} keys={{
        tags: { type: "STR_ARRAY", name: "Tags" },
        data: { type: "STRING", name: "Data" },
        timestamp: { type: "DATE", name: "Date" }
      }} />
    </TabPanel>
    <TabPanel value={tab} index={0}>
      <UserLookup onNeedExploreDetailed={(text) => setDetailedViewText(text)} token={token}  />
    </TabPanel>
    <TabPanel value={tab} index={1}>
      <AllUsers onNeedExploreDetailed={(text) => setDetailedViewText(text)} token={token}  />
    </TabPanel>
    <TabPanel value={tab} index={2}>
    <PaginatedList path={"challenges/list"} token={token} keys={{
        id: { type: "STRING", name: "Id" },
        gameName: { type: "STRING", name: "Game" },
        active: { type: "BOOLEAN", name: "Active" },
        minBetAmount: { type: "CURRENCY", name: "Min Bet Amount" },
        minMultiplier: { type: "MULTIPLIER", name: "Min Multiplier" },
        startTime: { type: "DATE", name: "Start Date" },
        endTime: { type: "DATE", name: "End Date" },
        prize: { type: "CURRENCY", name: "Prize" },
        wonByUserId: { type: "STRING", name: "Won By" },
        createdByUserId: { type: "STRING", name: "Created By" },
        _manage: [
        {
          name: "Deactivate", onClick: (item, refresh) => {
            fetchAPI("admin/challenges/" + item.id + "/deactivate", "GET", null, true, token).then(() => {
              showToast("success", "Challenge deactivated!");
              refresh();
            });
          }
        }]
      }} />
    </TabPanel>
    <TabPanel value={tab} index={3}>
    <CreateChallenge token={token} />
    </TabPanel>
    <TabPanel value={tab} index={9}>
    <PaginatedList path={"listTransactions"} onNeedExploreDetailed={(text) => setDetailedViewText(text)} token={token} keys={{
        id: { type: "STRING", name: "Id" },
        timestamp: { type: "DATE", name: "Date" },
        userId: { type: "STRING", name: "User Id" },
        otherParty: { type: "STRING", name: "Other Party" },
        amount: { type: "CURRENCY", name: "Amount" },
        type: { type: "PRETTY_STRING", name: "Type" },
        data: { type: "DETAILED_VIEW", name: "Data" },
        status: { type: "String", name: "Status" }
      }} />
    </TabPanel>
    <TabPanel value={tab} index={10}>
      <BetLookup onNeedExploreDetailed={(text) => setDetailedViewText(text)} token={token}  />
    </TabPanel>
    
  </>);
}