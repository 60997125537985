import logo from './logo.svg';
import './App.css';
import * as React from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import MainApp from './components/MainApp.js';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
  useLocation
} from "react-router-dom";

const GameImage = (props) => {
  const [hovered, setHovered] = React.useState(false);

  return (
    <a href="#">
      <div onMouseOver={() => setHovered(true)} onMouseOut={() => setHovered(false)} style={{ transform: `${hovered ? 'scale(1.075)' : 'scale(1)'}`, transition: `transform .4s` }} sx={{ boxShadow: 5 }}>
        <img style={{ borderRadius: '4px', width: '100%', height: '100%', maxHeight: 200 }} src={props.imageUrl} />
      </div>
    </a>
  );
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function App() {
  return (
    <>
      <ToastContainer
        position="top-left"
        max
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={3}
      />

     {/* <Router>
        <Switch>
     <Route>*/}
     <Router>
            <MainApp />
            </Router>
          {/*</Route>
        </Switch>
          </Router>*/}
    </>
  );
}

export default App;
