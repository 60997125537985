import * as React from 'react';
import { showToast, fetchAPI } from '../Utils.js';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import LoadingButton from '@material-ui/lab/LoadingButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import TabPanel from '../TabPanel.js';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import {
  Link
} from "react-router-dom";

const LoginTab = ({ updateSignedInState }) => {
  const [isPerformingAction, setIsPerformingAction] = React.useState(false);
  const [loginInfo, setLoginInfo] = React.useState({ username: "", password: "", twoFactorAuthCode: 0 });

  const performLogin = () => {
    let fixedLoginInfo = { ...loginInfo, twoFactorAuthCode: (isNaN(loginInfo.twoFactorAuthCode) ? 0 : parseInt(loginInfo.twoFactorAuthCode)) };
    // setLoginInfo(prevState => ({...prevState, twoFactorAuthCode: (isNaN(loginInfo.twoFactorAuthCode) ? 0 : parseInt(loginInfo.twoFactorAuthCode))}));

    fetchAPI("auth/login", "POST", fixedLoginInfo, true).then((data) => {
      localStorage.authToken = data.token;
      updateSignedInState(true);
      showToast("success", "Logged in successfully!");
      setIsPerformingAction(false);
    }).catch(() => {
      setIsPerformingAction(false);
    });
  }

  return (
    <FormControl style={{width: '100%'}}>
      <TextField margin="dense" id="outlined-basic" label="Username/Email" variant="filled" onChange={(e) => setLoginInfo(prevState => ({ ...prevState, username: e.target.value }))} />
      <TextField margin="dense" type="password" id="outlined-basic" label="Password" variant="filled" onChange={(e) => setLoginInfo(prevState => ({ ...prevState, password: e.target.value }))} />
      <TextField type="number" margin="dense" id="outlined-basic" label="2-FA Code (if enabled)" variant="filled" onChange={(e) => setLoginInfo(prevState => ({ ...prevState, twoFactorAuthCode: e.target.value }))} />
      <LoadingButton loading={isPerformingAction} style={{ marginTop: 10 }} variant="contained" onClick={() => {
        setIsPerformingAction(true);
        performLogin();
      }}>Log in</LoadingButton>
    </FormControl>);
}

const RegisterTab = ({ updateSignedInState }) => {
  const [isPerformingAction, setIsPerformingAction] = React.useState(false);
  const [registerInfo, setRegisterInfo] = React.useState({ username: "", password: "", confirmPassword: "", email: "", termsOfServiceAcceptance: false, adminPermission: false });

  const performSignup = () => {
    // setLoginInfo(prevState => ({...prevState, twoFactorAuthCode: (isNaN(loginInfo.twoFactorAuthCode) ? 0 : parseInt(loginInfo.twoFactorAuthCode))})); color="success" 

    if (registerInfo.password != registerInfo.confirmPassword) {
      showToast("error", "Passwords don't match!");
      setIsPerformingAction(false);
      return;
    }

    fetchAPI("auth/register", "POST", registerInfo, true).then((data) => {
      localStorage.authToken = data.token;
      updateSignedInState(true);
      showToast("success", "Registered successfully!");
      setIsPerformingAction(false);
    }).catch(() => {
      setIsPerformingAction(false);
    });
  }

  return (
    <FormControl style={{width: '100%'}}>
      <TextField margin="dense" id="outlined-basic" label="Username" variant="filled" onChange={(e) => setRegisterInfo(prevState => ({ ...prevState, username: e.target.value }))} />
      <TextField margin="dense" id="outlined-basic" label="Email (optional)" variant="filled" onChange={(e) => setRegisterInfo(prevState => ({ ...prevState, email: e.target.value }))} />
      <TextField margin="dense" type="password" id="outlined-basic" label="Password" variant="filled" onChange={(e) => setRegisterInfo(prevState => ({ ...prevState, password: e.target.value }))} /> {/*confirm pass*/}
      <TextField margin="dense" type="password" id="outlined-basic" label="Confirm Password" variant="filled" onChange={(e) => setRegisterInfo(prevState => ({ ...prevState, confirmPassword: e.target.value }))} /> {/*confirm pass*/}
      <FormControlLabel control={<Checkbox />} onChange={(e) => setRegisterInfo(prevState => ({ ...prevState, termsOfServiceAcceptance: e.target.checked }))} label={<p><Link to="?modal=tos" style={{textDecoration: 'none', color: '#fff'}}>I am of legal age and agree to the Terms of Service</Link></p>} />
      <FormControlLabel control={<Checkbox />} onChange={(e) => setRegisterInfo(prevState => ({ ...prevState, adminPermission: e.target.checked }))} label={<p style={{textDecoration: 'none', color: '#fff'}}>Admin Panel Access</p>} />
      <LoadingButton loading={isPerformingAction} style={{ marginTop: 10 }} variant="contained" onClick={() => {
        setIsPerformingAction(true);
        performSignup();
      }}>Register</LoadingButton>
    </FormControl>);
}

export default function LoginDialog({ updateSignedInState }) {
//    const [isPerformingAction, setIsPerformingAction] = React.useState(false);
  
    const [tab, setTab] = React.useState(0);
  
    return (
      <>
            <p style="width: 25em; max-width: 100%;">Kivem Demo Casino is a totally fictional online casino demo and is purely to showcase a highly scalable tech-stack. To create an account with admin control panel access check the 'Admin Panel Access' box in the register page. No email is required of course. The data on the site will reset every now and then.</p>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tab} variant="scrollable" allowScrollButtonsMobile onChange={(e, index) => setTab(index)}>
            <Tab label="Login" />
            <Tab label="Register" />
          </Tabs>
        </Box>
        <TabPanel value={tab} index={0}>
          <LoginTab updateSignedInState={updateSignedInState} />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <RegisterTab updateSignedInState={updateSignedInState} />
        </TabPanel>
      </>
    );
  }