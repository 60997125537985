//import React from 'react';
//import ReactDOM from 'react-dom';
import { render } from 'preact';
import './index.css';
import App from './App';
import { createTheme, ThemeProvider, CssBaseline } from '@material-ui/core';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#007bff',
    },
    secondary: {
      main: '#2f4553',
    },
    background: {
      default: '#0f212e',
      paper: '#0f212e',
    },
    success: {
      main: '#1fff20',
      contrastText: '#013e01',
    },
    error: {
      main: '#fe2247',
    },
    // info: {
    // main: '#0f212e',
    //},
  },
  components: {
    // Name of the component ⚛️
    MuiButtonBase: {
      defaultProps: {
        // The props to apply
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
    },
  },
});

render(
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>,
  document.getElementById('root')
);

function consoleWarnUser() {
  console.log("%cSTOP!!!", "background: red; color: white; font-size: xxx-large");
  console.log("%cImportant Security Notice", "background: red; color: white; font-size: x-large");
  console.log("%cThis is a browser feature intended for developers only.\n\nDo not paste anything here as it could compromise the security of your account. If you have been told to paste something here as a \"hack\" then it is probably a scam and may lead to someone gaining access to your account.\n\nThere are no approved console scripts that are safe to use.", "color: black; font-size: large");
}

window.onload = setTimeout(consoleWarnUser, 2500);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
