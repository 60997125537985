import * as React from 'react';
import { showToast, fetchAPI } from '../Utils.js';

import Skeleton from '@material-ui/core/Skeleton';

import Paper from '@material-ui/core/Paper';
import Alert from '@material-ui/core/Alert';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';

import Typography from '@material-ui/core/Typography';

import {
    useHistory,
} from "react-router-dom";

export default function TextDialog({text}) {
    return (
        <>
             <Typography variant="body1" gutterBottom>
        {text}
      </Typography>
        </>
    );
}