import * as React from 'react';

import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/core/Skeleton';

import {
    useParams,
    Link
} from "react-router-dom";

import { fetchAPI } from '../Utils';

import PulseLoader from "react-spinners/PulseLoader";

const GameImage = (props) => {
    const [hovered, setHovered] = React.useState(false);

    return (
        <Link to={props.path}>
            <div onMouseOver={() => setHovered(true)} onMouseOut={() => setHovered(false)} style={{ transform: `${hovered ? 'scale(1.075)' : 'scale(1)'}`, transition: `transform .4s` }} sx={{ boxShadow: 5 }}>
                <img style={{ borderRadius: '4px', width: '100%', height: '100%', maxHeight: 200 }} src={props.imageUrl} />
            </div>
        </Link>
    );
}

const LoadingTemplate = () => {
    return (
        <Grid container style={{ justifyContent: 'center' }} spacing={{ xs: 1, md: 2, lg: 2 }}>
            <Grid item xs={4} md={4} lg={4}>
                <Skeleton height={400} width={600} style={{ maxHeight: '100%', maxWidth: '100%' }} />
            </Grid>
            <Grid item xs={4} md={4} lg={4}>
                <Skeleton height={400} width={600} style={{ maxHeight: '100%', maxWidth: '100%' }} />
            </Grid>
            <Grid item xs={4} md={4} lg={4}>
                <Skeleton height={400} width={600} style={{ maxHeight: '100%', maxWidth: '100%' }} />
            </Grid>
            <Grid item xs={12} md={12}>
                <Skeleton height={400} width={1800} style={{ maxHeight: '100%', maxWidth: '100%' }} />

            </Grid>
            <Grid item xs={12} md={12}>
                <Skeleton height={400} width={1800} style={{ maxHeight: '100%', maxWidth: '100%' }} />
            </Grid>
        </Grid>
    );
}
/*
<Grid container style={{ justifyContent: 'center' }} spacing={{ xs: 1, md: 2, lg: 2 }}>
        <Grid item xs={4} md={4} lg={4}>
            <GameImage imageUrl="https://via.placeholder.com/600x400" />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
            <GameImage imageUrl="https://via.placeholder.com/600x400" />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
            <GameImage imageUrl="https://via.placeholder.com/600x400" />
        </Grid>
        <Grid item xs={12} md={12}>
            <GameImage imageUrl="https://via.placeholder.com/1800x400" />
        </Grid>
        <Grid item xs={12} md={12}>
            <GameImage imageUrl="https://via.placeholder.com/1800x400" />
        </Grid>
    </Grid>*/
export default function CategoryView({ isSignedIn }) {
    let { category } = useParams();

    const [games, setGames] = React.useState([]);
    const [gamesShouldHaveFinishedLoading, setGamesShouldHaveFinishedLoading] = React.useState(false);

    React.useEffect(() => {
        if (isSignedIn && games.length == 0) {
            fetchAPI("category/" + (category == null ? "featured" : category), "GET").then((data) => {
                var final = [];

                for (var i = 0; i < data.length; i++) {
                    var obj = data[i];
                    final.push(obj.displayInfo);
                    //setGames(prevState => ([...prevState, data.displayInfo]));
                }

                setGames(final);

                console.log(final);

                setTimeout(() => {
                    setGamesShouldHaveFinishedLoading(true);
                }, 750);
            });
        }
    }, [isSignedIn]);
    /*
    <Grid container style={{ justifyContent: 'center' }} spacing={{ xs: 1, md: 2, lg: 2 }}>
            <Grid item xs={4} md={4} lg={4}>
    
                <GameImage imageUrl="https://via.placeholder.com/600x400" />
            </Grid>
            <Grid item xs={4} md={4} lg={4}>
                <GameImage imageUrl="https://via.placeholder.com/600x400" />
            </Grid>
            <Grid item xs={4} md={4} lg={4}>
                <GameImage imageUrl="https://via.placeholder.com/600x400" />
            </Grid>
            <Grid item xs={12} md={12}>
                <GameImage imageUrl="https://via.placeholder.com/1800x400" />
            </Grid>
            <Grid item xs={12} md={12}>
                <GameImage imageUrl="https://via.placeholder.com/1800x400" />
            </Grid>
        </Grid>*/
    return (<><Grid container style={{ justifyContent: 'center' }} spacing={{ xs: 1, md: 2, lg: 2 }}>
        {games.map((data, i) => <Grid item style={{display: !gamesShouldHaveFinishedLoading ? 'none' : ''}} key={i} xs={(data.type == "FULL" ? 12 : (data.type == "HALF_BLOCK" ? 6 : 4))}>
            <GameImage imageUrl={data.imageUrl} path={data.urlPathName} />
        </Grid>)}
    </Grid> 
    {!gamesShouldHaveFinishedLoading && <div style={{ paddingTop: 50, marginRight: 'auto', marginLeft: 'auto', height: 200, width: 256 }}><PulseLoader color="white" margin={10} size={35} speedMultiplier={0.8} /></div>}</>);
}