import * as React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import LoadingButton from '@material-ui/lab/LoadingButton';

import { getUserGameSessionId, getGameSession, actOnGameSession, placeGameBet, Keyframes, showToast, useInterval, CURRENCY_DENOMINATOR } from '../Utils';

import { eventBus } from "../EventBus";

import { createChart, CrosshairMode } from 'lightweight-charts';

import useSound from 'use-sound';

import betStart from '../../sounds/bet_start.wav';
import cashout3 from '../../sounds/cashout_3.wav';

import BetAmountBox from '../BetAmountBox';

export class CrashChart extends React.PureComponent {
  constructor() {
    super();
    this.chartRef = React.createRef();
  }

  getChart() {
    return this.chart;
  }

  getAreaSeries() {
    return this.areaSeries;
  }

  componentDidMount() {
    this.chart = createChart(this.chartRef.current, {
      layout: {
        backgroundColor: '#0c1a24',
        lineColor: '#2B2B43',
        textColor: '#D9D9D9',
      },
      handleScroll: {
        mouseWheel: false,
        pressedMouseMove: false,
        horzTouchDrag: false,
        vertTouchDrag: false
      },
      handleScale: {
        axisPressedMouseMove: false,
        mouseWheel: false,
        pinch: false,
      },
      grid: {
        horzLines: {

          visible: false,
        },
        vertLines: {
          visible: false,
        },
      },
      width: 800,
      height: 600,
      rightPriceScale: {
        visible: false,
      },
      leftPriceScale: {
        visible: true,
      },
      timeScale: {
        timeVisible: true,
        secondsVisible: false,
        tickMarkFormatter: (time, tickMarkType, locale) => {
          var m = Math.round((Date.now() - this.props.startedAt) / 1000);;
          return String(m + "s");
        },
      },
      crosshair: {
        vertLine: {
          width: 5,
          color: 'rgba(224, 227, 235, 0.1)',
          style: 0,
          labelVisible: false,
        },
        horzLine: {
          visible: false,
          labelVisible: false,
        },
        mode: CrosshairMode.Normal,
      },
    });

    this.areaSeries = this.chart.addAreaSeries({
      topColor: 'rgba(33, 150, 243, 0.56)',
      bottomColor: 'rgba(33, 150, 243, 0.04)',
      lineColor: 'rgba(33, 150, 243, 1)',
      lineWidth: 6, //4
    });

    var _data = [{
      time: 1,
      value: 1.00
    }];

    var currentValue = 0.01;

    this.areaSeries.setData(_data);
  }

  componentWillUnmount() {
    if (this.chart !== null) {
      this.chart.remove();
      this.chart = null;
    }
  }

  render() {
    return (
      <div style={{ position: 'relative' }}>
        <div ref={this.chartRef} style={{ position: 'relative' }}>          {this.props.children}
        </div></div>
    )
  }
}

export default function CrashGame({ webSocketConnected, balance, tokenPrice, isSignedIn }) {
  const [playBetStart] = useSound(betStart);
  const [playCashout] = useSound(cashout3);

  const appCrashRef = React.useRef(null);

  const [isBusy, setIsBusy] = React.useState(false);

  const [pendingBet, setPendingBet] = React.useState(null);

  const [autoCashout, setAutoCashout] = React.useState(0);
  const [autoCashoutInput, setAutoCashoutInput] = React.useState("0");
  const [betAmount, setBetAmount] = React.useState(0);

  const [gameSession, setGameSession] = React.useState(null);

  const [chartData, setChartData] = React.useState([]);

  const [gamePhase, setGamePhase] = React.useState(null); // ingame, ended, starting and next phase time
  const [gameState, setGameState] = React.useState(null); // multipliers elapsed time, has crashed and so on

  const [multiplier, setMultiplier] = React.useState(0);

  const chartRef = React.useRef();
  const [width, setWidth] = React.useState(0);

  const calculateMultiplier = (startedAt) => {
    return Math.pow((1.0 + 1.0 / 1100.0), ((Date.now() - startedAt) / 15));
  }

  const updateDimensions = () => {
    setWidth(appCrashRef.current.offsetWidth);
    chartRef.current.getChart().resize(appCrashRef.current.offsetWidth, Math.min(600, appCrashRef.current.offsetWidth));
  }

  const reset = () => { // rest bets data and such
    //setGameState(prevState => ({...prevState, }));
    setGameSession(null);
    setIsBusy(false);
  }

  React.useEffect(() => {
    if (gamePhase != null && gamePhase.type == "ACCEPTING_BETS" && pendingBet != null) {
      console.log("bet");
      setIsBusy(true);

      placeGameBet("crash", pendingBet).then((data) => {
        getGameSession(data.sessionId).then((data) => {
          playBetStart();
          setIsBusy(false);
          setGameSession(data);
        });
      }).catch(() => {
        setIsBusy(false);
      });

      setPendingBet(null);
    }
  }, [gamePhase]);

  const doCashout = () => {
    setIsBusy(true);

    actOnGameSession(gameSession.id, { type: "CASHOUT" }).then((data) => {
      playCashout();
      /*if (data.type == "ACCEPTED") {
        //playCashout();
        reset();
      //  setGameSession({ id: null });
      }*/
      reset();
    }).catch(() => {
      reset();
     // setIsBusy(false);
    });
  }

  React.useEffect(() => {
    if (!webSocketConnected)
      return;

    eventBus.dispatch("subscribe", { channel: "game", subChannel: "crash" });
    eventBus.dispatch("subscribe", { channel: "gameHistory", subChannel: "crash" });

    function eventBusCallback(data) {
      if (data.type == "GAME_PHASE_UPDATE") {
        setGamePhase({ type: data.data.type, nextPhaseTime: data.data.nextPhaseTime, nextPhaseUnixTime: Date.now() + data.data.nextPhaseTime });

        if (data.data.type == "INGAME") {
          setChartData([]);
          chartRef.current.getAreaSeries().setData([]);
          setGameState({ crashed: { crashedAt: 0, hasCrashed: false } });
        } else if (data.data.type == "ENDED") {
          reset();
        }
      } else if (data.type == "GAME_EVENT") {
        if (data.data.type == "MULTIPLIER_UPDATE") {
          setGameState({ elapsedTime: Date.now() - data.data.data.elapsed, crashed: { hasCrashed: false } });
        } else if (data.data.type == "MULTIPLIER_CRASH") {
          setGameState({ crashed: { hasCrashed: true, crashedAt: data.data.data.multiplier } });
        }
      }
    }

    let thing = eventBus.on("game", eventBusCallback);

    return () => {
      eventBus.remove("game", thing);
      eventBus.dispatch("unSubscribe", { channel: "game", subChannel: "crash" });
      eventBus.dispatch("unSubscribe", { channel: "gameHistory", subChannel: "crash" });
    };
  }, [webSocketConnected]);

  useInterval(() => {
    if (gamePhase == null || (gamePhase.type != "INGAME" && gamePhase.type != "STARTING")) {
      return;
    } else if (gamePhase.type == "STARTING") {
      setGamePhase(prevState => ({ ...prevState, nextPhaseUnixTime: prevState.nextPhaseUnixTime, nextPhaseTime: prevState.nextPhaseUnixTime - Date.now() }));
      return;
    }

    var tempData = chartData;

    if (tempData.length >= 200 * 2) {
      tempData.splice(0, 100 * 2);
    }

    tempData.push({ time: Date.now(), value: calculateMultiplier(gameState.elapsedTime) });

    setMultiplier(calculateMultiplier(gameState.elapsedTime).toFixed(2));
    setChartData(tempData);

    if (chartRef != null && chartRef.current != null) {
      chartRef.current.getAreaSeries().setData(tempData);
    }

    if (gameState != null && gameSession != null && multiplier > parseFloat(gameSession.bets[0].data.autoCashoutMultiplier) && !isBusy)
    {
      setIsBusy(true);
      doCashout();
    }
  }, 15);

  React.useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  React.useEffect(() => {
    if (!isSignedIn)
      return;

    setIsBusy(true);

    getUserGameSessionId("crash").then((sessionId) => {
      setIsBusy(false);

      if (sessionId != null) {
        setIsBusy(true);

        getGameSession(sessionId).then((data) => {
          setIsBusy(false);
          setGameSession(data);

          setBetAmount(data.bets[0].amount);
          setAutoCashout(data.bets[0].data.autoCashoutMultiplier);
          setAutoCashoutInput(data.bets[0].data.autoCashoutMultiplier + "");
        });
      }
    });
  }, [isSignedIn]);

  const getDisplayText = () => {
    if (gamePhase.type == "INGAME" || gamePhase.type == "ENDED") {
      if (gameState != null && gameState.crashed != null && gameState.crashed.hasCrashed) {
        return (<>
          <p style={{ fontSize: (width / 8.5), userSelect: 'none', color: 'red', fontFamily: 'Arial', }}>Crashed at</p>
          <p style={{ fontSize: (width / 8.5), userSelect: 'none', color: 'red', fontFamily: 'Arial', marginTop: '-20%' }}>{gameState.crashed.crashedAt.toFixed(2)}x</p>
        </>);
      } else {
        return (
          <div>
            <p style={{ fontSize: (width / 6.5), userSelect: 'none', color: 'lightblue', fontFamily: 'Arial' }}>{multiplier > 500 ? <div style={{ backgroundImage: '-webkit-linear-gradient(120deg,rgb(10, 151, 252),rgb(10, 252, 220),rgb(10, 252, 79));', backgroundClip: 'text', animation: '1.25s linear 0s infinite normal none running sca' }}>{multiplier + "x"}<br /><img style={{ width: (width / 6.5), height: (width / 6.5) }} src="https://media1.giphy.com/media/10D8j2EpNCXDA4/giphy.gif?cid=ecf05e47jw07xbm3ndu74p31z4756nhrqvfpxntkr48xlq6j&rid=giphy.gif&ct=g" /></div> : !isNaN(multiplier) && multiplier + "x"}</p>
          </div>
        );
      }
    } else {
      if (gamePhase.nextPhaseTime <= 0 || gamePhase.type != "STARTING") {
        return (
          <p style={{ fontSize: (width / 8.5), userSelect: 'none', color: 'lightblue', fontFamily: 'Arial' }}>Starting</p>
        );
      } else {
        return (<>
          <p style={{ fontSize: (width / 8.5), userSelect: 'none', color: 'lightblue', fontFamily: 'Arial', }}>Starting in</p>
          <p style={{ fontSize: (width / 8.5), userSelect: 'none', color: 'lightblue', fontFamily: 'Arial', marginTop: '-20%' }}>{(gamePhase.nextPhaseTime / 1000).toFixed(2)}s</p>
        </>);
      }
    }
  }

  const getCurrentProfit = () => {
    try {
      return (parseFloat(multiplier) * gameSession.bets[0].amount).toFixed(2);
    } catch (e) {
      return 0;
    }
  }

  return (
    <Box style={{ width: '100%', borderRadius: '5px', backgroundColor: '#213743' }}>
      <Keyframes name="sca" from={{ filter: `hue-rotate(0deg)` }} to={{ filter: `hue-rotate(-360deg)` }} />
      <Grid container direction="row-reverse" style={{ width: '100%', height: '100%' }}>

        <Grid item xs={12} md={9} ref={appCrashRef} align="center" style={{ borderRadius: '5px', backgroundColor: '#0c1a24' }}>
          <CrashChart startedAt={gameState == null ? 0 : gameState.elapsedTime} ref={chartRef}>
            <div style={{ position: 'absolute', zIndex: 50, pointerEvents: 'none', width: '95%', left: '5%', height: '100%', display: 'flex', alignContent: 'center', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
              {gamePhase != null && getDisplayText()}
            </div>
            <div style={{ width: '100%', height: '100%', backdropFilter: (gameState == null || gamePhase == null || gamePhase.type != "INGAME" ? 'blur(3px)' : ''), pointerEvents: 'none', zIndex: 49, position: 'absolute' }} />
          </CrashChart>
        </Grid>

        <Grid item xs={12} md={3} style={{ paddingTop: 15 }}>
          {gameSession == null ? <>
            <BetAmountBox disabled={pendingBet != null || isBusy} value={betAmount} balance={balance} onChange={(e) => setBetAmount(e)}/>
            <TextField
              disabled={pendingBet != null || isBusy}
              id="outlined-number"
              type="number"
              variant="filled"
              value={autoCashoutInput}
              step="0.01"
              onChange={(event) => {
                setAutoCashoutInput(event.target.value);

                if(/^\d+(\.\d+)?/.exec(event.target.value))
                {
                  setAutoCashout(parseFloat(event.target.value));
                }
              }}
              sx={{ m: 1, width: '75%' }}
              label="Auto Cashout Multiplier"
            />
            <TextField
              disabled
              id="outlined-number"
              type="number"
              variant="filled"
              InputProps={{
                startAdornment: <InputAdornment position="start">{CURRENCY_DENOMINATOR}</InputAdornment>,
              }}
              value={(autoCashout * betAmount).toFixed(2)}
              sx={{ m: 1, width: '75%' }}
              label="Potential Profit"
            />

            <LoadingButton disabled={gamePhase != null && gamePhase.type == "ACCEPTING_BETS"} loading={isBusy} variant="contained" color="success" sx={{ m: 2, width: '75%', minHeight: '8%' }} onClick={() => {
              if (pendingBet == null) {
                if (betAmount == 0) {
                  showToast("error", "The bet amount needs to be higher than 0!");
                } else if (autoCashout != null && autoCashout > 0 && autoCashout < 1.01) {
                  showToast("error", "Auto cashout multiplier needs to be empty or higher than 1.01x!");
                } else {
                  setPendingBet([{ amount: betAmount, data: { autoCashoutMultiplier: autoCashout.toFixed(2) } }]);
                  setAutoCashoutInput(autoCashout.toFixed(2));
                }
              } else {
                setPendingBet(null);
              }
            }}>{pendingBet == null ? (gamePhase != null && gamePhase.type != "STARTING" ? "Bet Next Round" : "Bet") : "Cancel"}</LoadingButton>
          </> : <>
          <BetAmountBox disabled value={gameSession.bets[0].amount} balance={balance}/>
            <TextField
              disabled
              id="outlined-number"
              type="number"
              variant="filled"
              defaultValue={0}
              value={getCurrentProfit()}
              sx={{ m: 1, width: '75%' }}
              InputProps={{
                startAdornment: <InputAdornment position="start">{CURRENCY_DENOMINATOR}</InputAdornment>,
              }}
              label={"Current Profit (" + (isNaN(multiplier) ? "1" : parseFloat(multiplier).toFixed(2)) + "x)"}
            />
            <TextField
              disabled
              id="outlined-number"
              type="number"
              variant="filled"
              value={autoCashoutInput}
              step="0.01"
              onChange={(event) => {
                setAutoCashoutInput(event.target.value);

                if(/^\d+(\.\d+)?/.exec(event.target.value))
                {
                  setAutoCashout(parseFloat(event.target.value));
                }
              }}
              sx={{ m: 1, width: '75%' }}
              label="Auto Cashout Multiplier"
            />
            <LoadingButton loading={isBusy} variant="contained" color="success" sx={{ m: 3, width: '75%', minHeight: '8%' }} disabled={isNaN(multiplier) || parseFloat(multiplier) < 1} onClick={() => {
              doCashout();
            }}>Cashout</LoadingButton>
          </>}
        </Grid>
      </Grid>
    </Box>
  );
}