import * as React from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Zoom from '@material-ui/core/Zoom';
import Fade from '@material-ui/core/Fade';

import LoadingButton from '@material-ui/lab/LoadingButton';

import useSound from 'use-sound';

import betStart from '../../sounds/bet_start.wav';
import cashout3 from '../../sounds/cashout_3.wav';
import cashout from '../../sounds/cashout.wav';
import click from '../../sounds/click.wav';
import hitMine from '../../sounds/hit_mine_2.wav';
import correctTile from '../../sounds/correct_tile_2.wav';

import click1 from '../../sounds/clicks_1.wav';
import click2 from '../../sounds/clicks_2.wav';
import click3 from '../../sounds/clicks_3.wav';
import click4 from '../../sounds/clicks_4.wav';
import click5 from '../../sounds/clicks_5.wav';
import click6 from '../../sounds/clicks_6.wav';
import click7 from '../../sounds/clicks_7.wav';
import click8 from '../../sounds/clicks_8.wav';
import click9 from '../../sounds/clicks_9.wav';
import click10 from '../../sounds/clicks_10.wav';

import BetAmountBox from '../BetAmountBox.js';

import { getUserGameSessionId, getGameSession, actOnGameSession, placeGameBet, Keyframes, formatMultiplier, CURRENCY_DENOMINATOR } from '../Utils';

const Mine = ({ onClicked, mineType, disabled, clickedInitial }) => {
    const [hovered, setHovered] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const [clicked, setClicked] = React.useState(0);

    React.useEffect(() => {
        if (mineType != null) {
            setClicked(clicked + 1);
            setLoading(false);
        } else {
            setClicked(0);
        }
    }, [mineType]);

    function handleClick() {
        if (!disabled && mineType == null && !loading) {
            setClicked(1);
            setLoading(true);
            onClicked();
        }
    }

    return (
        <Box onClick={() => handleClick()} onMouseOver={() => { if (mineType == null && !disabled) { setHovered(true); } }} onMouseOut={() => setHovered(false)} style={{ width: '100%', height: '100%', boxShadow: (mineType == null ? '0px 7px #213743' : ''), backgroundColor: (!clicked ? '#2f4553' : '#2f4553'), borderRadius: 5, animation: `${loading ? 'scaleAnimLoadMine .4s alternate-reverse infinite ease-in' : ''}`, transform: `${hovered ? 'scale(1.04)' : 'scale(1)'}`, transition: `transform .4s` }}>
            <Fade in={clicked == 2 || clickedInitial}><div style={{ borderRadius: 5, position: 'absolute', height: '100%', width: '100%', backgroundColor: '#132a36' }}></div></Fade>
            
            {clicked != 2 && !clickedInitial && mineType != null && <div style={{ borderRadius: 5, position: 'absolute', height: '100%', width: '100%', zIndex: 5000, opacity: '50%', backgroundColor: 'black' }}></div>}

            <Button style={{ visibility: mineType != null ? 'hidden' : '', height: '100%', width: '100%' }} />

            <Zoom in={mineType == "gem"}>
                <div style={{ position: 'absolute', top: '15%', left: '0%' }}><img style={{ height: '75%', width: '75%', userSelect: 'none' }} src="https://cdn-icons-png.flaticon.com/512/263/263399.png"></img></div>
            </Zoom>

            <Zoom in={mineType == "bomb"}>
                <div style={{ position: 'absolute', top: '10%', left: '0%' }}><img style={{ height: '100%', width: '100%', userSelect: 'none' }} src="https://i.imgur.com/ziMa4NQ.png"></img></div>
            </Zoom>
        </Box>
    );
}

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 48 * 4.5 + 8
        },
    },
};

export default function MinesGame({ balance, tokenPrice, isSignedIn }) {
    const [playBetStart] = useSound(betStart);
    const [playCashout3] = useSound(cashout3);
    const [playHitMine] = useSound(hitMine);

    const [playClick1] = useSound(click1);
    const [playClick2] = useSound(click2);
    const [playClick3] = useSound(click3);
    const [playClick4] = useSound(click4);
    const [playClick5] = useSound(click5);
    const [playClick6] = useSound(click6);

    const [gameSession, setGameSession] = React.useState(null);
    const [lastGameActionState, setLastGameActionState] = React.useState(null);

    const appMinesGridRef = React.useRef(null);

    const [width, setWidth] = React.useState(0);
    const [height, setHeight] = React.useState(0);

    const [waitingToBePushed, setWaitingToBePushed] = React.useState([]);

    const [minesAmount, setMinesAmount] = React.useState(3);
    const [betAmount, setBetAmount] = React.useState(0);

    const updateDimensions = () => {
        setWidth(Math.min(650, appMinesGridRef.current.offsetWidth));
        setHeight(Math.min(650, appMinesGridRef.current.offsetWidth));
    }

    React.useEffect(() => {
        setWidth(Math.min(650, appMinesGridRef.current.offsetWidth));
        setHeight(Math.min(650, appMinesGridRef.current.offsetWidth));
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    const [currentTimeout, setCurrentTimeout] = React.useState(0);

    const [isBusy, setIsBusy] = React.useState(false);

    const reset = () => {
        clearTimeout(currentTimeout);
        setWaitingToBePushed([]);
        setGameSession({ id: null });
        setIsBusy(false);
    }

    React.useEffect(() => {
        clearTimeout(currentTimeout);

        setCurrentTimeout(setTimeout(() => {
            if (waitingToBePushed.length > 0 && !isBusy) {
                setIsBusy(true);
                var am = waitingToBePushed.length;
                actOnGameSession(gameSession.id, { type: "REVEAL_MINE", tiles: waitingToBePushed }).then((data) => {
                    setLastGameActionState(data.data);

                    if (data.data.hitMine) {
                        playHitMine();
                        clearTimeout(currentTimeout);
                        reset();
                    } else {
                        for (var i = 0; i < am; i++) {
                            var s = (25 - gameSession.bets[0].data.amountBombs) - data.data.publicState.minesPressed.length + (i);
                        //    console.log(s);
                            if (s <= 0) {
                                playClick6();
                            } else if (s <= 4) {
                                playClick5();
                            } else if (s <= 8) {
                                playClick4();
                            } else if (s <= 12) {
                                playClick3();
                            } else if (s <= 16) {
                                playClick2();
                            } else {
                                playClick1();
                            }
                        }
                        /* var s = (25 - gameSession.bets[0].data.amountBombs) - data.data.publicState.minesPressed.length;
                         console.log(s);
                         if(s <= 0)
                         {
                             playClick6();
                         } else if(s <= 4)
                         {
                             playClick5();
                         } else if(s <= 8)
                         {
                             playClick4();
                         } else if(s <= 12)
                         {
                             playClick3();
                         } else if(s <= 16) {
                             playClick2();
                         } else {
                             playClick1();
                         }*/
                        //playCorrect({playbackRate: Math.max(am / 8, 1)});
                        if (data.data.publicState.minesPressed.length == (25 - gameSession.bets[0].data.amountBombs)) {
                            clearTimeout(currentTimeout);
                            setIsBusy(true);
                            actOnGameSession(gameSession.id, { type: "CASHOUT" }).then((data) => {
                                if (data.type == "ACCEPTED") {
                                    reset();
                                    playCashout3();
                                    setLastGameActionState(data.data);
                                    return;
                                }
                            });
                        } else {
                            setIsBusy(false);
                        }
                    }
                });

                console.log("cleared waiting to be pushed");
               // setWaitingToBePushed([]);
               setWaitingToBePushed(prevState => prevState.filter(function(val, i) {
                   return !waitingToBePushed.includes(val);
               }));
            } else if (waitingToBePushed.length > 0 && isBusy) {
                if (lastGameActionState.publicState.minesPressed.length + waitingToBePushed.length + 1 <= (25 - gameSession.bets[0].data.amountBombs)) {
                    setWaitingToBePushed(prevState => ([...prevState]));
                }
            }
        }, 300));

      //  return () => clearTimeout(currentTimeout);
    }, [waitingToBePushed]);

    React.useEffect(() => {
        if (!isSignedIn)
            return;

        setIsBusy(true);

        getUserGameSessionId("mines").then((sessionId) => {
            setIsBusy(false);

            if (sessionId != null) {
                setIsBusy(true);

                getGameSession(sessionId).then((data) => {
                    setIsBusy(false);
                    setLastGameActionState(data.state);
                    setGameSession(data);

                    setBetAmount(data.bets[0].amount);
                    setMinesAmount(data.bets[0].data.amountBombs);
                });
            }
        });
    }, [isSignedIn]);

    const getTypeOfMine = (index) => {
        if (lastGameActionState.privateState != null) {
            return (lastGameActionState.privateState.bombs.includes(index) ? "bomb" : "gem");
        }

        return (lastGameActionState.publicState.minesPressed.includes(index) ? "gem" : null);
    }

    function onClick(index) {
        if (lastGameActionState.publicState.minesPressed.length + waitingToBePushed.length + 1 <= (25 - gameSession.bets[0].data.amountBombs)) {
            setWaitingToBePushed(prevState => ([...prevState, index]));
            console.log(index + " " + JSON.stringify(waitingToBePushed));
        }
    }

    return (
        <Box style={{ width: '100%', borderRadius: '5px', backgroundColor: '#213743' }}>
            <Keyframes name="scaleAnimLoadMine" from={{ transform: `scale(1)` }} to={{ transform: `scale(1.1)` }} /> {//1.07 1.075 1.06
            }

            <Grid container direction="row-reverse" style={{ width: '100%', height: '100%' }}>

                <Grid item xs={12} md={9} ref={appMinesGridRef} align="center" style={{ borderRadius: '5px', backgroundColor: '#0c1a24' }}>
                    <Grid container columns={{ xs: 5 }} spacing={1.5} style={{ padding: 10, height, width }}>
                        {[...Array(25)].map((x, i) => <Grid item xs={1} key={i}>{
                            gameSession == null ?
                                <Mine key={i} clickedInitial={null} disabled />
                                :
                                <Mine key={i} clickedInitial={lastGameActionState.publicState.minesPressed.includes(i + 1)} onClicked={() => onClick(i + 1)} mineType={getTypeOfMine(i + 1)} />
                        }</Grid>
                        )}
                    </Grid>
                </Grid>

                <Grid item xs={12} md={3} style={{ paddingTop: 15 }}>
                    {gameSession == null || gameSession.id == null ? <>
                        <BetAmountBox disabled={isBusy} onChange={(e) => setBetAmount(e)} value={betAmount} balance={balance}/>
                        <TextField style={{ m: 3, width: '75%' }}
                        disabled={isBusy}
                            id="outlined-select-currency"
                            select
                            label="Mines"
                            variant="filled"
                            defaultValue={minesAmount}
                            sx={{ m: 1, width: '75%' }}
                            onChange={(event) => { setMinesAmount(event.target.value) }}
                            SelectProps={{ MenuProps: MenuProps }}
                        >
                            {[...Array(24)].map((x, i) => <MenuItem key={i + 1} value={i + 1}>
                                {'' + (i + 1)}
                            </MenuItem>)}
                        </TextField>

                        <LoadingButton loading={isBusy} variant="contained" color="success" sx={{ m: 2, width: '75%', minHeight: '8%' }} onClick={() => {
                            setIsBusy(true);

                            placeGameBet("mines", [{ amount: betAmount, data: { amountBombs: minesAmount } }]).then((data) => {
                                getGameSession(data.sessionId).then((data) => {
                                    playBetStart();
                                    setIsBusy(false);
                                    setLastGameActionState(data.state);
                                    setGameSession(data);
                                });
                            }).catch(() => {
                                setIsBusy(false);
                            });
                        }}>Bet</LoadingButton>
                    </> : <>
                        <BetAmountBox disabled value={gameSession.bets[0].amount} balance={balance}/>
                        <TextField
                            disabled
                            id="outlined-number"
                            type="number"

                            //defaultValue={0}
                            value={(lastGameActionState.publicState.currentPayout * gameSession.bets[0].amount).toFixed(2)}
                            sx={{ m: 1, width: '75%' }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">{CURRENCY_DENOMINATOR}</InputAdornment>,
                            }}
                            label={"Total Profit (" + formatMultiplier(lastGameActionState.publicState.currentPayout) + ")"}
                            variant="filled"
                        />
                        <TextField
                            disabled
                            id="outlined-number"
                            type="number"

                            defaultValue={0}
                            value={((lastGameActionState.publicState.currentPayout.toFixed(2) * lastGameActionState.publicState.nextTilePayout * gameSession.bets[0].amount) - (lastGameActionState.publicState.currentPayout * gameSession.bets[0].amount)).toFixed(2)}
                            sx={{ m: 1, width: '75%' }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">{CURRENCY_DENOMINATOR}</InputAdornment>,
                            }}
                            label={"Profit Next Gem (" + formatMultiplier(lastGameActionState.publicState.nextTilePayout) + ")"}
                            variant="filled"
                        />
                        <LoadingButton loading={isBusy} variant="contained" color="success" sx={{ m: 2, width: '75%', minHeight: '8%' }} onClick={() => {
                            setIsBusy(true);

                            actOnGameSession(gameSession.id, { type: "CASHOUT" }).then((data) => {
                                if (data.type == "ACCEPTED") {
                                    reset();
                                    playCashout3();
                                    setGameSession({ id: null });
                                    setLastGameActionState(data.data);
                                }
                            }).catch(() => {
                                setIsBusy(false);
                            });
                        }}>Cashout</LoadingButton>
                    </>}
                </Grid>

            </Grid>
        </Box>
    );
}