import * as React from 'react';
import { showToast, fetchAPI } from '../Utils.js';

import Skeleton from '@material-ui/core/Skeleton';

import Paper from '@material-ui/core/Paper';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';

import { translateAmountToCurrencyWithPrefix } from '../Utils.js';

export default function ToSDialog() {
    return (
        <>
            <p>Kivem Demo Casino is not a real casino and no money is ever exchanged and can never be redeemed.</p>
        </>
    );
}