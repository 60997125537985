import * as React from 'react';
import { showToast, fetchAPI } from '../Utils.js';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import LoadingButton from '@material-ui/lab/LoadingButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Divider from '@material-ui/core/Divider';

import CircularProgress from '@material-ui/core/CircularProgress';

import TabPanel from '../TabPanel.js';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import { makeString } from '../Utils.js';

export default function SeedPairDialog() {
    const [isPerformingAction, setIsPerformingAction] = React.useState(false);

    const [newClientSeed, setNewClientSeed] = React.useState(makeString(16));
    const [seedData, setSeedData] = React.useState(null);

    const [tab, setTab] = React.useState(0);

    const updateSeedPair = () => {
        setIsPerformingAction(true);

        fetchAPI("account/fair", "GET").then((data) => {
            setSeedData(data);
            setIsPerformingAction(false);
        });
    }

    React.useEffect(() => {
        updateSeedPair();
    }, []);

    const rotateSeedPair = () => {
        setIsPerformingAction(true);

        fetchAPI("account/fair", "POST", { clientSeed: newClientSeed }).then((data) => {
            showToast("success", data.message);
        }).finally(() => {
            // setIsPerformingAction(false);
            updateSeedPair();
        })
    }

    return (<>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tab} variant="scrollable" allowScrollButtonsMobile onChange={(e, index) => setTab(index)}>
                <Tab label="Current & Next" />
                <Tab label="Previous" />
            </Tabs>
        </Box>
        <TabPanel value={tab} index={0}><>
        <FormControl sx={{ m: 1, width: '40ch', maxWidth:'100%' }}>
        {seedData != null && seedData.current != null &&<>
        
               
            <TextField disabled id="outlined-basic" label="Current Client Seed" variant="filled" value={seedData.current.clientSeed} />
            <TextField disabled margin="dense" id="outlined-basic" label="Current Server Hash" variant="filled" value={seedData.current.serverHash} />
            <TextField disabled type="number" margin="dense" id="outlined-basic" label="Bets Made with Current Seed Pair" variant="filled" value={seedData.current.nonce} /></>}
           
            {seedData != null &&seedData.next != null &&<>
                <Divider sx={{marginTop: 1}}/>
            <TextField disabled margin="normal" id="outlined-basic" label="Next Server Hash" variant="filled" value={seedData.next.serverHash} />
            <TextField margin="dense" id="outlined-basic" label="Next Client Seed" variant="filled" value={newClientSeed} onChange={(e) => setNewClientSeed(e.target.value)} />
            <LoadingButton loading={isPerformingAction} style={{ marginTop: 10 }} variant="contained" onClick={() => {
                rotateSeedPair();
            }}>Rotate to next seed pair</LoadingButton>
      
            </>
            
            }
            </FormControl>
            </>
        </TabPanel>
        <TabPanel value={tab} index={1}>
        <FormControl sx={{ m: 1, width: '40ch', maxWidth:'100%' }}>
           {seedData != null && seedData.previous != null &&<>
     
            <TextField disabled id="outlined-basic" label="Client Seed" variant="filled" value={seedData.previous.clientSeed} />
            <TextField disabled margin="dense" id="outlined-basic" label="Server Seed" variant="filled" value={seedData.previous.serverSeed} />
            <TextField disabled margin="dense" id="outlined-basic" label="Server Hash" variant="filled" value={seedData.previous.serverHash} />
            <TextField disabled type="number" margin="dense" id="outlined-basic" label="Bets Made" variant="filled" value={seedData.previous.nonce} /></>}
            </FormControl>
        </TabPanel>
    </>);
}